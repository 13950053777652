import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import applicationsReducer from "./reducers/applicationsReducer"
import breadcrumbReducer from "./reducers/breadcrumbsReducer"

const store = configureStore({
    reducer: {
        user: userReducer,
        applications: applicationsReducer,
        breadcrumbs: breadcrumbReducer
    }
});

export default store;