/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { instance } from './app';
import { useSelector } from 'react-redux';

const useInsertFlow = () => {
    const { user } = useSelector(store => store);
    const [insertFlowReq, setInsertFlowReq] = useState(undefined);
    const [insertFlowAccountingReq, setInsertFlowAccountingReq] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [flowError, setFlowError] = useState("");

    //#region Billing
    const getInsertFlowRequest = (object) => {
        setInsertFlowReq(object);
    }

    const insertFlow = async () => {
        setIsLoading(true);
        try {
            if (insertFlowReq === undefined)
                return null;
            if (user.email === null) {
                return null;
            }
            await instance.post(`/flow/insert-flow`, insertFlowReq).then(response => {
                if (response.status !== 200)
                    throw new Error("Can't insert new flow");
                else
                    setFlowError("NoError");
            });
        } catch (error) {
            console.log("error: " + error);
            setFlowError(error.response.data.error.message);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        insertFlow();
    }, [insertFlowReq])

    //#endregion

    //#region Accounting
    const getInsertFlowAccountingRequest = (object) => {
        setInsertFlowAccountingReq(object);
    }

    const insertFlowAccounting = async () => {
        setIsLoading(true);
        try {
            if (insertFlowAccountingReq === undefined)
                return null;
            if (user.email === null) {
                return null;
            }
            await instance.post(`/flow/accounting/insert-flow`, insertFlowAccountingReq).then(response => {
                if (response.status !== 200)
                    throw new Error("Can't insert new flow");
                else
                    setFlowError("NoError");
            });
        } catch (error) {
            console.log("error: " + error);
            setFlowError(error.response.data.error.message);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        insertFlowAccounting();
    }, [insertFlowAccountingReq])
    //#endregion
    return { getInsertFlowRequest, getInsertFlowAccountingRequest, isLoading, flowError }
}

export default useInsertFlow