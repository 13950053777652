import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ShowInfoNotification } from '../../Shared/Notification';
import useAzureFunction from '../../../CustomHook/useAzureFunction'

const TableBilling = ({ data, getFlowName }) => {
    const notificationId = "flowDownloadNotification";
    const { setUploadFlowReq } = useAzureFunction();
    return (
        <div className="table-responsive-lg">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Info</th>
                        <th scope="col">Nome Flusso</th>
                        <th scope="col" className='text-center'>Fatt.</th>
                        <th scope="col" className='text-center'>Fatt.OK</th>
                        <th scope="col" className='text-center'>Stato Creazione</th>
                        <th scope="col" className='text-center'>Stato Upload</th>
                        <th scope="col" className='text-center'>Stato Importazione</th>
                        <th scope="col" className='text-center'>Invio Cliente</th>
                        <th scope="col" className='text-center'>Fatt. Elett.</th>
                        <th scope="col">Up</th>
                        <th scope="col">Down</th>
                    </tr>
                </thead>
                {
                    data.items === undefined ?
                        <tbody className='text-center'>
                            <tr>
                                <td colSpan="12">Nessun risultato</td>
                            </tr>
                        </tbody>
                        :
                        <tbody>
                            {
                                data.items.map((el, index) => {
                                    return (
                                        <tr key={index} className="form-control-sm">
                                            <td>
                                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li className="dropdown-item">Utente Creazione: {el.user}</li>
                                                    <li className="dropdown-item">Data Invio: {el.sendDate}</li>
                                                    <li className="dropdown-item">Escludi Sci-Scf: {el.excludeSciScf ? "SI" : "NO"}</li>
                                                </ul>
                                            </td>
                                            <td>{el.name}</td>
                                            <td className='text-center'>{el.invoicesNumber}</td>
                                            <td className='text-center'>{el.invoicesNumberOK}</td>
                                            <td className='text-center'>
                                                {(() => {
                                                    switch (el.idCreation) {
                                                        case 1:
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'green' }} />
                                                        case 3:
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'red' }} />
                                                        case 4:
                                                            return <FontAwesomeIcon icon="fa-solid fa-cog" spin />
                                                        default:
                                                            return <></>
                                                    }
                                                })()}
                                            </td>
                                            <td className='text-center'>
                                                {(() => {
                                                    switch (el.idUpload) {
                                                        case 1:
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'green' }} />
                                                        case 2:
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'red' }} />
                                                        default:
                                                            return <></>
                                                    }
                                                })()}
                                            </td>
                                            <td className='text-center'>
                                                {(() => {
                                                    switch (el.idImportation) {
                                                        case 1:
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'red' }} />
                                                        case 2:
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'green' }} />
                                                        case 3:
                                                            return <FontAwesomeIcon icon="fa-solid fa-cog" spin />
                                                        default:
                                                            return <></>
                                                    }
                                                })()}
                                            </td>
                                            <td className='text-center'>{el.send ? "SI" : "NO"}</td>
                                            <td className='text-center'>{el.electronicInvoicing ? "SI" : "NO"}</td>
                                            <td className='text-center'>
                                                {(() => {
                                                    if (el.idCreation === 1 && el.idUpload === null)
                                                        return <FontAwesomeIcon icon="fa-light fa-cloud-arrow-up" size='xl' onClick={() => { alert("Verrà inviata una mail quando l'upload sarà terminato"); setUploadFlowReq({ flowName: el.name, email: null }) }} style={{ cursor: "pointer" }} />
                                                    else
                                                        return <></>
                                                })()}
                                            </td>
                                            <td className='text-center'>
                                                {(() => {
                                                    if (el.idCreation === 1 && el.idUpload === null)
                                                        return <FontAwesomeIcon icon="fa-light fa-download" size='xl' onClick={() => {
                                                            ShowInfoNotification(notificationId, "Esportazione...");
                                                            getFlowName(el.name);
                                                        }} style={{ cursor: "pointer" }} />
                                                    else
                                                        return <></>
                                                })()}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                }
            </table>
        </div>
    )
}

export default TableBilling