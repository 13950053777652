/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { instance } from "../app"
import { useSelector } from 'react-redux';

const useFetchClimaticZone = () => {
    const { user } = useSelector(store => store);
    const [missing, setMissing] = useState(undefined);
    const [reclaim, setReclaim] = useState(undefined);
    //set to true!!!
    const [isLoading, setIsLoading] = useState(true);

    //#region get missing climatic zones
    const getMissing = async () => {
        try {
            if (!user.loggedIn)
                return null;
            setIsLoading(true);
            var response = await instance.get(`/climaticzone/missing`);
            if (response.status === 204)
                setMissing(undefined);
            else
                setMissing(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get climatic zones to reclaim
    const getReclaim = async () => {
        try {
            if (!user.loggedIn)
                return null;
            setIsLoading(true);
            var response = await instance.get(`/climaticzone/zones-to-reclaim`);
            if (response.status === 204)
                setReclaim(undefined);
            else
                setReclaim(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion
    return {
        getMissing,
        getReclaim,
        missing,
        reclaim,
        isLoading
    }
}

export default useFetchClimaticZone;
