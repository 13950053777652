import React, { useEffect } from 'react'
import Loading from '../Shared/Loading'
import useFetchNav from '../../CustomHook/FetchData/useFetchNav';

const NavSessions = () => {
    const { navSessionResponse, isLoadingNavSession, getNavSession } = useFetchNav();
    useEffect(() => {
        getNavSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='container'>
            <div>
                <h3>Sessioni Nav Fatturazione</h3>
            </div>
            {
                isLoadingNavSession ?
                    <Loading />
                    :
                    <div className="table-responsive-lg">
                        <table className="table">
                            <thead>
                                <tr >
                                    <th scope="col">Utente</th>
                                    <th scope="col">Login Timestamp</th>
                                    <th scope="col">Database</th>
                                    <th scope="col">Applicativo</th>
                                    <th scope="col">Nome Host</th>
                                    <th scope="col">Tempo Inattività</th>
                                </tr>
                            </thead>
                            {
                                navSessionResponse === undefined ?
                                    <tbody className='text-center'>
                                        <tr>
                                            <td colSpan="12">Nessun risultato</td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        {
                                            navSessionResponse.map((el, index) => {
                                                return (
                                                    <tr key={index} className="form-control-sm">
                                                        <td>{el.userId}</td>
                                                        <td>{el.loginTimeStamp}</td>
                                                        <td>{el.databaseName}</td>
                                                        <td>{el.applicationName}</td>
                                                        <td>{el.hostName}</td>
                                                        <td>{el.idleTime}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                            }
                        </table>
                    </div>
            }
        </div>
    )
}

export default NavSessions