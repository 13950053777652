/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import InvoiceTable from './Table';
import InvoiceFilters from './Filters';
import { usePagination } from '../../../CustomHook/usePagination';
import Pagination from '../../Shared/Pagination';
import useInvoiceToPrint from '../../../CustomHook/useInvoiceToPrint';
import ToastPrinting from "../../Shared/Invoice/ToastPrinting";
import CreateFlowModal from '../../Shared/CreateFlowModal';
import { useParams } from 'react-router-dom';
import { RemoveNotification, ShowCounterNotification } from '../../Shared/Notification';
import useFetchInvoice from '../../../CustomHook/FetchData/useFetchInvoice';
import Loading from '../../Shared/Loading';


function InvoiceList() {
  let { supplyTypeParam } = useParams();
  var supplyType;
  if (supplyTypeParam === "fattureelettrico")
    supplyType = "ee"
  else if (supplyTypeParam === "fatturegas")
    supplyType = "gas"

  //#region variables
  const idNotificationCounter = "CounterNotification";
  const idNotificationLoading = "LoadingNotification";
  //#endregion
  //#region useState
  const [selectAll, setSelectAll] = useState(false);
  const [errorCreation, setErrorCreation] = useState("false");
  const [invoiceData, setInvoiceData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [showCountNotification, setShowCountNotification] = useState(true);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [isError, setIsError] = useState(undefined);
  //#endregion

  //#region Custom Hook
  const { invoiceToPrint, addInvoiceToPrint, removeInvoiceToPrint, addAllInvoiceToPrint, removeAllInvoiceToPrint } = useInvoiceToPrint();
  const { invoicesToPrintResp, getInvoicesToPrintReq, isLoadingInvoicesToPrint } = useFetchInvoice();
  //#endregion

  //#region Function to pass to invoice filters
  const getSelectAll = (data) => {
    setSelectAll(data);
  }
  const getErrorCreation = (data) => {
    setErrorCreation(data);
  }
  const getInvoiceData = (data) => {
    setInvoiceData(data);
  }
  //#endregion

  //#region Pagination
  function goToNextPage(event) {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
  }

  function goToPreviousPage(event) {
    event.preventDefault();
    setCurrentPage(currentPage - 1);
  }

  function changePage(event) {
    event.preventDefault();
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  const paginationRange = usePagination({
    currentPage: currentPage,
    totalCount: invoiceData.count === undefined ? 0 : invoiceData.count,
    siblingCount: process.env.REACT_APP_PAGINATION_SIBLINGCOUNT,
    pageSize: process.env.REACT_APP_PAGINATION_PAGESIZE
  });
  //#endregion

  //#region Notification
  useEffect(() => {

    switch (isLoadingInvoicesToPrint) {
      case true:
        ShowCounterNotification(idNotificationLoading, "Caricamento elementi selezionati...");
        break;
      default:
        RemoveNotification(idNotificationLoading);
        break;
    }

    if (showNotification && !isLoadingInvoicesToPrint) {
      RemoveNotification(idNotificationLoading);
      ShowCounterNotification(idNotificationCounter, invoiceToPrint.length + " elementi selezionati");
      setShowCountNotification(true);

      if (invoicesToPrintResp !== null)
        if (invoiceToPrint.length === invoicesToPrintResp.length) {
          document.getElementById("selectAllCheckbox").checked = true;
          document.getElementById("selectAllLabel").textContent = "Deseleziona tutto";
        }
        else {
          document.getElementById("selectAllCheckbox").checked = false;
          document.getElementById("selectAllLabel").textContent = "Seleziona tutto";
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInvoicesToPrint, invoiceToPrint]);

  //hook to hide notification after 2 seconds and reset timeout if table checkbox is clicked
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!showCountNotification) return;

      RemoveNotification(idNotificationCounter);
      setShowCountNotification(false);
    }, 2000)

    return () => clearTimeout(timeoutId)
  }, [invoiceToPrint, showCountNotification])

  //hook to add all invoice for print
  useEffect(() => {
    addAllInvoiceToPrint(invoicesToPrintResp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesToPrintResp])
  //#endregion

  return (
    <div className='container'>
      <InvoiceFilters getSelectAll={getSelectAll} getErrorCreation={getErrorCreation} getInvoiceData={getInvoiceData} currentPage={currentPage} removeAllInvoiceToPrint={removeAllInvoiceToPrint} getInvoicesToPrintReq={getInvoicesToPrintReq} setIsLoadingInvoices={setIsLoadingInvoices} setShowNotification={setShowNotification} setIsError={setIsError} />
      <br />
      {
        isLoadingInvoices ?
          <Loading />
          :
          isError !== undefined ?
            <>{isError}</>
            :
            <>
              <InvoiceTable data={invoiceData} addInvoiceToPrint={addInvoiceToPrint} removeInvoiceToPrint={removeInvoiceToPrint} selectAll={selectAll} setShowNotification={setShowNotification} />
              <br />
              <Pagination paginationRange={paginationRange} currentPage={currentPage} goToPreviousPage={goToPreviousPage}
                changePage={changePage} data={invoiceData} goToNextPage={goToNextPage} />
              <br />
              <ToastPrinting invoiceToPrint={invoiceToPrint} errorCreation={errorCreation} />

              <CreateFlowModal invoicesToPrint={invoiceToPrint} supplyType={supplyType} />
            </>
      }
    </div>
  )
}

export default InvoiceList