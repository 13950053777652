import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { instance } from './app';

const useFunction = () => {
    const { user } = useSelector(store => store);

    const [assignRoleReq, setAssignRoleReq] = useState({ functionId: undefined, roleId: undefined });
    const [removeRoleReq, setRemoveRoleReq] = useState({ functionId: undefined, roleId: undefined });
    const [updateFunctionReq, setUpdateFunctionReq] = useState(undefined);
    const [addFunctionReq, setAddFunctionReq] = useState(undefined);

    const [addFunctionError, setAddFunctionError] = useState("");

    const [isAssignRoleToFunction, setIsAssignRoleToFunction] = useState(true);
    const [isRemoveRoleToFunction, setIsRemoveRoleToFunction] = useState(true);
    const [isUpdateFunction, setIsUpdateFunction] = useState(true);
    const [isAddFunction, setIsAddFunction] = useState(true);


    //#region assign role to function
    const assignRoleToFunction = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (assignRoleReq.functionId === undefined || assignRoleReq.roleId === undefined)
                return;
            setIsAssignRoleToFunction(true);
            var response = await instance.put(`/admin/function/${assignRoleReq.functionId}/assign-role/${assignRoleReq.roleId}`, null);
            if (response.status !== 200)
                console.error(response.message)
            setIsAssignRoleToFunction(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region remove role to function
    const removeRoleToFunction = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (removeRoleReq.functionId === undefined || removeRoleReq.roleId === undefined)
                return;
            setIsRemoveRoleToFunction(true);
            var response = await instance.delete(`/admin/function/${removeRoleReq.functionId}/remove-role/${removeRoleReq.roleId}`);
            if (response.status !== 200)
                console.error(response.message)
            setIsRemoveRoleToFunction(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region update function
    const updateFunction = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (updateFunctionReq === undefined)
                return;
            setIsUpdateFunction(true);
            var response = await instance.patch(`/admin/function`, updateFunctionReq);
            if (response.status !== 200)
                console.error(response.message)
            setIsUpdateFunction(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region add function
    const addFunction = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (addFunctionReq === undefined)
                return;
            setIsAddFunction(true);
            var response = await instance.put(`/admin/function`, addFunctionReq);
            if (response.status !== 200)
                console.error(response.message)
            setIsAddFunction(false);
        } catch (error) {
            console.error(error);
            setAddFunctionError(error.response.data.error.message);
        }
    }
    //#endregion

    useEffect(() => {
        assignRoleToFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignRoleReq])

    useEffect(() => {
        removeRoleToFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeRoleReq])

    useEffect(() => {
        updateFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateFunctionReq])

    useEffect(() => {
        addFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addFunctionReq])

    return {
        setAssignRoleReq,
        setRemoveRoleReq,
        setUpdateFunctionReq,
        setAddFunctionReq,
        addFunctionError,
        isAssignRoleToFunction,
        isRemoveRoleToFunction,
        isUpdateFunction,
        isAddFunction
    }
}

export default useFunction