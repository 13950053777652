import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import RolesDetail from './Detail/RolesDetail';
import FunctionDetail from './Detail/FunctionDetail';
import RolesModal from './RolesModal';
const Detail = () => {
  let { functionId } = useParams();
  const [functionRolesUnassigned, setFunctionRolesUnassigned] = useState(undefined);
  const [reloadFunctionRoles, setReloadFunctionRoles] = useState(false);
  return (
    <>
      <RolesDetail functionId={functionId} setFunctionRolesUnassigned={setFunctionRolesUnassigned} reloadFunctionRoles={reloadFunctionRoles} />
      <br />
      <FunctionDetail functionId={functionId} />
      <RolesModal functionRolesUnassigned={functionRolesUnassigned} functionId={functionId} setReloadFunctionRoles={setReloadFunctionRoles} />
    </>
  )
}

export default Detail