/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { instanceDownload } from "./app";
import { useSelector } from 'react-redux';

const useExportExcel = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [exportRequest, setExportRequest] = useState(undefined);
    const [exportResponse, setExportResponse] = useState(null);
    const { user } = useSelector(store => store);

    //#region export file excel
    const getExportRequest = (object, exportError) => {
        setExportRequest(object);
        if (exportError === true || exportError === false)
            setIsError(exportError);
        else
            setIsError(false);
    }
    const getInvoiceExportFile = async () => {

        try {
            setIsLoading(true);
            if (!user.loggedIn)
                return null;
            if (exportRequest === undefined)
                return null;
            var response = await instanceDownload.post(`/invoice/export-invoice-excel?isError=${isError}`, exportRequest,);
            setExportResponse(response.data);
        } catch (error) {
            console.log("error; " + error);
            return null;
        }
        setIsLoading(false);
    }
    useEffect(() => {
        getInvoiceExportFile();
    }, [exportRequest]);
    //#endregion

    return { isLoading, getExportRequest, exportResponse }
}

export default useExportExcel;
