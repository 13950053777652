import React from 'react'
import ClimaticZoneMissing from './ClimaticZoneMissing'
import ClimaticZoneReclaim from './ClimaticZoneReclaim'

const Home = () => {
    return (
        <div className='container'>
            <ClimaticZoneMissing />
            <br />
            <ClimaticZoneReclaim />
        </div>
    )
}

export default Home