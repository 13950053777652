import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import useFetchSetup from '../../../CustomHook/FetchData/useFetchSetup';
import useFetchFlow from '../../../CustomHook/FetchData/useFetchFlow'
import { ShowInfoNotification, RemoveNotification } from '../../Shared/Notification';
import { IsAccounting } from '../../Shared/Common'

const FlowFilters = ({ getFlowData, currentPage, setIsLoadingFlows }) => {
  const { user } = useSelector(store => store);

  const isAccounting = IsAccounting();
  //#region Variables
  const idSetupNotification = "LoadingSetup";
  //#endregion
  //#region Custom Hooks
  const { getSetupFlow, creationStatus, uploadStatus, importationStatus, isLoadingSetup, accountingCompanies, creationStatusAccounting, uploadStatusAccounting, importationStatusAccounting } = useFetchSetup();
  const { flowPaginatedResp, getFlowPaginatedReq, isLoading, getPageNumber, getIsAccounting } = useFetchFlow();
  //#endregion

  //#region get setup and start search
  useEffect(() => {
    if (!user.loggedIn)
      return;
    getSetupFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.checking])
  //#endregion

  //#region Local Functions
  const getSearchParameters = () => {
    getIsAccounting(isAccounting);
    if (isAccounting)
      return {
        company: document.getElementById("accountingCompaniesSelect").value === "" ? undefined : document.getElementById("accountingCompaniesSelect").value,
        flowName: document.getElementById("flowName").value === "" ? undefined : document.getElementById("flowName").value,
        creationId: document.getElementById("creationStatusSelect").value === "" ? undefined : document.getElementById("creationStatusSelect").value,
        uploadId: document.getElementById("uploadStatusSelect").value === "" ? undefined : document.getElementById("uploadStatusSelect").value,
        importationId: document.getElementById("importationStatusSelect").value === "" ? undefined : document.getElementById("importationStatusSelect").value,
        userCreation: document.getElementById("userCreation").value === "" ? undefined : document.getElementById("userCreation").value,
        electronicInvoicing: document.getElementById("electronicInvoicing").value === "" ? undefined : JSON.parse(document.getElementById("electronicInvoicing").value.toLowerCase()),
        autobasket: document.getElementById("autobasket").value === "" ? undefined : JSON.parse(document.getElementById("autobasket").value.toLowerCase()),
        noImport: document.getElementById("noImport").value === "" ? undefined : JSON.parse(document.getElementById("noImport").value.toLowerCase())
      }
    else
      return {
        flowName: document.getElementById("flowName").value === "" ? undefined : document.getElementById("flowName").value,
        creationId: document.getElementById("creationStatusSelect").value === "" ? undefined : document.getElementById("creationStatusSelect").value,
        uploadId: document.getElementById("uploadStatusSelect").value === "" ? undefined : document.getElementById("uploadStatusSelect").value,
        importationId: document.getElementById("importationStatusSelect").value === "" ? undefined : document.getElementById("importationStatusSelect").value,
        userCreation: document.getElementById("userCreation").value === "" ? undefined : document.getElementById("userCreation").value,
        electronicInvoicing: document.getElementById("electronicInvoicing").value === "" ? undefined : JSON.parse(document.getElementById("electronicInvoicing").value.toLowerCase()),
        autobasket: document.getElementById("autobasket").value === "" ? undefined : JSON.parse(document.getElementById("autobasket").value.toLowerCase()),
        noImport: document.getElementById("noImport").value === "" ? undefined : JSON.parse(document.getElementById("noImport").value.toLowerCase())
      }
  }
  //#endregion

  useEffect(() => {
    getPageNumber(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  //#region Handle submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    const search = getSearchParameters();
    getFlowPaginatedReq(search);
  };
  //#endregion

  //#region UseEffect
  useEffect(() => {
    getFlowData(flowPaginatedResp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowPaginatedResp])
  //#endregion

  //#region Notification
  //loading notification
  useEffect(() => {
    if (isLoadingSetup) {
      ShowInfoNotification(idSetupNotification, "Caricamento Setup...");
    }
    else {
      RemoveNotification(idSetupNotification);
      //set timeout to permit importationStatusSelect to set default value
      setTimeout(() => {
        getFlowPaginatedReq(getSearchParameters());
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSetup])

  //search notification
  useEffect(() => {
    setIsLoadingFlows(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])
  //#endregion

  return (
    <>
      <form className='row g-3' onSubmit={handleSubmit}>
        {
          isAccounting ?
            <div className='col-md-4'>
              <label className="form-label">Società</label>
              <select className="form-select" aria-label="Default select example" id="accountingCompaniesSelect" >
                {
                  accountingCompanies.map((el, index) => {
                    return <option key={index} value={el.id}>{el.description}</option>
                  })
                }
              </select>
            </div>
            :
            <></>
        }
        <div className='col-md-2'>
          <label className="form-label">Nome Flusso</label>
          <input className="form-control" type="text" aria-label="default input example" id="flowName" />
        </div>
        <div className='col-md-2'>
          <label className="form-label">Stato Creazione</label>
          <select className="form-select" aria-label="Default select example" id="creationStatusSelect" >
            <option value="">Tutti...</option>
            {
              isAccounting ?
                creationStatusAccounting.map((el, index) => {
                  return <option key={index} value={el.value}>{el.description}</option>
                })
                :
                creationStatus.map((el, index) => {
                  return <option key={index} value={el.value}>{el.description}</option>
                })
            }
          </select>
        </div>
        <div className='col-md-2'>
          <label className="form-label">Stato Upload</label>
          <select className="form-select" aria-label="Default select example" id="uploadStatusSelect" >
            <option value="">Tutti...</option>
            {
              isAccounting ?
                uploadStatusAccounting.map((el, index) => {
                  return <option key={index} value={el.value}>{el.description}</option>
                })
                :
                uploadStatus.map((el, index) => {
                  return <option key={index} value={el.value}>{el.description}</option>
                })
            }
          </select>
        </div>
        <div className='col-md-2'>
          <label className="form-label">Stato Importazione</label>
          <select className="form-select" aria-label="Default select example" id="importationStatusSelect" defaultValue={"Non importati"}>
            {
              isAccounting ?
                importationStatusAccounting.map((el, index) => {
                  return <option key={index} value={el.value}>{el.description}</option>
                })
                :
                importationStatus.map((el, index) => {
                  return <option key={index} value={el.value}>{el.description}</option>
                })
            }
          </select>
        </div>
        <div className='col-md-2'>
          <label className="form-label">Utente Creazione</label>
          <input className="form-control" type="text" aria-label="default input example" id="userCreation" />
        </div>
        <div className='col-md-2'>
          <label className="form-label">Fatt. Elettronica</label>
          <select className="form-select" aria-label="Default select example" id="electronicInvoicing" >
            <option value="">Tutte...</option>
            <option value="false">No</option>
            <option value="true">Sì</option>
          </select>
        </div>
        <div className='col-md-2'>
          <label className="form-label">Autobasket</label>
          <select className="form-select" aria-label="Default select example" id="autobasket" defaultValue={"false"}>
            <option value="">Tutte...</option>
            <option value="false">No</option>
            <option value="true">Sì</option>
          </select>
        </div>
        <div className='col-md-2'>
          <label className="form-label">Da Non Importare</label>
          <select className="form-select" aria-label="Default select example" id="noImport" defaultValue={"false"}>
            <option value="">Tutte...</option>
            <option value="false">No</option>
            <option value="true">Sì</option>
          </select>
        </div>
        {
          isAccounting ?
            <div className='col-md-4' />
            :
            <div className='col-md-8' />
        }

        <div className='col-md-2'>
          <button type="submit" className="btn btn-lg btn-primary" id="btnFlowSearch">Cerca</button>
        </div>
      </form>
    </>
  )
}

export default FlowFilters