import { useState, useEffect } from "react";
import { instance } from './app';
import { useSelector } from 'react-redux';

const useUser = () => {
    const { user } = useSelector(store => store);

    const [deleteUserRoleReq, setDeleteUserRoleReq] = useState({ userId: undefined, roleId: undefined });
    const [isDeletingUserRole, setIsDeletingUserRole] = useState(true);
    const [addUserRoleReq, setAddUserRoleReq] = useState({ userId: undefined, roleId: undefined });
    const [isAddingUserRole, setIsAddingUserRole] = useState(true);
    const [addUserReq, setAddUserReq] = useState(undefined);
    const [isAddingUser, setIsAddingUser] = useState(true);

    //#region delete user role
    const deleteUserRole = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (deleteUserRoleReq.userId === undefined || deleteUserRoleReq.roleId === undefined)
                return;
            setIsDeletingUserRole(true);
            var response = await instance.delete(`/user/${deleteUserRoleReq.userId}/role/${deleteUserRoleReq.roleId}`);
            if (response.status !== 200)
                console.error(response.message)

            setIsDeletingUserRole(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region add user role
    const addUserRole = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (addUserRoleReq.userId === undefined || addUserRoleReq.roleId === undefined)
                return;
            setIsAddingUserRole(true);
            var response = await instance.put(`/user/${addUserRoleReq.userId}/role/${addUserRoleReq.roleId}`, null);
            if (response.status !== 200)
                console.error(response.message)

            setIsAddingUserRole(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region add user
    const addUser = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (addUserReq === undefined)
                return;
            setIsAddingUser(true);
            var response = await instance.put(`/user`, addUserReq);
            if (response.status !== 200)
                console.error(response.message)

            setIsAddingUser(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    useEffect(() => {
        deleteUserRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteUserRoleReq])

    useEffect(() => {
        addUserRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addUserRoleReq])

    useEffect(() => {
        addUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addUserReq])

    return {
        setDeleteUserRoleReq,
        setAddUserRoleReq,
        setAddUserReq,
        isDeletingUserRole,
        isAddingUserRole,
        isAddingUser
    }
}

export default useUser