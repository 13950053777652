import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import useFetchAdmin from '../../../CustomHook/FetchData/useFetchAdmin';
import useFunction from '../../../CustomHook/useFunction'
import Loading from '../../Shared/Loading';
import Alert from '../../Shared/Alert'

const NewFunctionModal = ({ setFunctionReq }) => {
    const { user } = useSelector(store => store);

    const { getApplications, applications, isLoading } = useFetchAdmin();
    const { setAddFunctionReq, isAddFunction, addFunctionError } = useFunction();

    useEffect(() => {
        if (user.loggedIn)
            getApplications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking])

    useEffect(() => {
        if (!isAddFunction) {
            document.getElementById('closeModalButton').click();
            setFunctionReq({ applicationId: "", functionName: "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddFunction])

    const [alias, setAlias] = useState("");
    const [applicationId, setApplicationId] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [position, setPosition] = useState("");
    const [hide, setHide] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setAddFunctionReq({
            applicationId,
            alias,
            name,
            description,
            hide,
            position
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="modal fade" id="newFunctionModal" tabIndex="-1" aria-labelledby="newFunctionModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="newFunctionModalLabel">Nuova funzione
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='closeModalButton'></button>
                        </div>
                        <div className="modal-body">
                            <Alert type="danger" message={addFunctionError} show={addFunctionError !== ""} />
                            {
                                isLoading ?
                                    <Loading />
                                    :
                                    <>
                                        <br />
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" value={alias} onChange={(e) => setAlias(e.target.value)} required />
                                            <label >Alias</label>
                                        </div>
                                        <div className="form-floating">
                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={applicationId} onChange={(e) => setApplicationId(e.target.value)}>
                                                <option value="">Seleziona applicativo...</option>
                                                {
                                                    applications.map((el, index) => {
                                                        return <option key={index} value={el.id}>{el.name}</option>
                                                    })
                                                }
                                            </select>
                                            <label htmlFor="floatingSelect">Applicativo</label>
                                        </div>
                                        <br />
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                                            <label >Nome</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                            <label >Descrizione</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={hide} onChange={(e) => setHide(e.target.checked)} />
                                            <label className="form-check-label" >
                                                Nascondi
                                            </label>
                                        </div>
                                        <br />
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" value={position} onChange={(e) => setPosition(e.target.value)} required />
                                            <label >Posizione</label>
                                        </div>
                                    </>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" >Salva</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default NewFunctionModal