import React, { useEffect } from 'react'
import Loading from '../../Shared/Loading'
import { useNavigate } from "react-router-dom";
import { ShowSuccessNotification, ShowErrorTimerNotification } from '../../Shared/Notification';
import { useSelector, useDispatch } from 'react-redux';
import { resetPassword } from '../../../redux/Functions/userReduxFunctions';

const Table = ({ users, isLoading }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector(store => store);

    useEffect(() => {
        if (user.resetPassword.isResetPassword && user.resetPassword.isResetPasswordError === null) {
            ShowSuccessNotification("Mail per reset password inviata correttamente!", 3);
        }
        else if (!user.resetPassword.isResetPassword && user.resetPassword.isResetPasswordError !== null) {
            ShowErrorTimerNotification("Errore durante il reset della password. Verificare la console.", 3);
            console.log(user.resetPassword.isResetPasswordError);
        }
    }, [user.resetPassword.isResetPassword, user.resetPassword.isResetPasswordError])

    return (
        isLoading ?
            <Loading />

            :

            <div className="table-responsive-lg">
                <table className="table">
                    <thead>
                        <tr >
                            <th scope="col">Nome</th>
                            <th scope="col">Cognome</th>
                            <th scope="col">Email</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    {
                        users === undefined ?
                            <tbody className='text-center'>
                                <tr>
                                    <td colSpan="12">Nessun risultato</td>
                                </tr>
                            </tbody>
                            :
                            <tbody>
                                {
                                    users.map((el, index) => {
                                        return (
                                            <tr key={index} className="form-control-sm" valign="middle">
                                                <td>{el.name}</td>
                                                <td>{el.surname}</td>
                                                <td>{el.email}</td>
                                                <td>
                                                    <button type='button' className='btn btn-primary' onClick={() => { dispatch(resetPassword(el.email, false)); }}>Reset password</button>
                                                </td>
                                                <td>
                                                    <button type='button' className='btn btn-primary' onClick={() => navigate(el.id)} >Dettagli</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                    }
                </table>
            </div>
    )
}

export default Table