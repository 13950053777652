/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { instance } from "../app"
import { useSelector } from 'react-redux';

const useFetchAccountingInvoice = () => {
    const { user } = useSelector(store => store);
    const [invoicePaginatedReq, setInvoicePaginatedReq] = useState(undefined);
    const [invoicePaginatedResp, setInvoicePaginatedResp] = useState([]);
    const [invoicesToPrintReq, setInvoicesToPrintReq] = useState(undefined);
    const [invoicesToPrintResp, setInvoicesToPrintResp] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingInvoicesToPrint, setIsLoadingInvoicesToPrint] = useState(undefined);
    const pageSize = process.env.REACT_APP_PAGINATION_PAGESIZE;

    //#region invoice paginated
    const getInvoicePaginatedReq = (object) => {
        setCurrentPage(1);
        setInvoicePaginatedReq(object);
    }

    const getInvoicePaginated =
        async () => {
            setIsLoading(true);
            try {
                if (invoicePaginatedReq === undefined)
                    return null;
                if (user.email === null) {
                    return null;
                }
                var response = await instance.post(`/invoice/accounting/invoices-paginated?pageSize=${pageSize}&pageNumber=${currentPage}&sort=NumeroFattura`, invoicePaginatedReq);
                setInvoicePaginatedResp(response.data);
            } catch (error) {
                console.log("error: " + error);
            }
            setIsLoading(false);
        }

    useEffect(() => {
        getInvoicePaginated();
    }, [invoicePaginatedReq, currentPage]);
    //#endregion

    //#region get invoice to print
    const getInvoicesToPrint =
        async () => {
            try {
                if (invoicesToPrintReq === undefined)
                    return null;
                if (user.email === null) {
                    return null;
                }
                setIsLoadingInvoicesToPrint(true);
                var response = await instance.post(`/invoice/accounting/invoices-list`, invoicesToPrintReq);
                // console.log(response.data);
                setInvoicesToPrintResp(response.data);
            } catch (error) {
                console.log("error: " + error);
            }
            setIsLoadingInvoicesToPrint(false);
        }

    useEffect(() => {
        getInvoicesToPrint();
    }, [invoicesToPrintReq]);
    //#endregion

    return {
        invoicePaginatedResp,
        getInvoicePaginatedReq,
        setCurrentPage,
        isLoadingInvoicesToPrint,
        isLoading,
        setInvoicesToPrintReq,
        invoicesToPrintResp
    }
}

export default useFetchAccountingInvoice;
