import React from 'react'
import Loading from '../../Shared/Loading';
import { useNavigate } from "react-router-dom";

const Table = ({ functions, isLoadingFunctions }) => {
    let navigate = useNavigate();
    
    return (
        isLoadingFunctions ?
            <Loading />
            :
            <>
                <div className="table-responsive-lg">
                    <table className="table">
                        <thead>
                            <tr >
                                <th scope="col">Posizione</th>
                                <th scope="col">Applicativo</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Alias</th>
                                <th scope="col">N° ruoli</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        {
                            functions === undefined ?
                                <tbody className='text-center'>
                                    <tr>
                                        <td colSpan="12">Nessun risultato</td>
                                    </tr>
                                </tbody>
                                :
                                <tbody>
                                    {
                                        functions.map((el, index) => {
                                            return (
                                                <tr key={index} className="form-control-sm" valign="middle">
                                                    <td>{el.functionPosition}</td>
                                                    <td>{el.applicationName}</td>
                                                    <td>{el.functionName}</td>
                                                    <td>{el.functionAlias}</td>
                                                    <td>{el.roleCount}</td>
                                                    <td>
                                                        <button type='button' className='btn btn-primary' onClick={() => navigate(el.functionId)} >Dettagli</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                        }
                    </table>
                </div>
            </>
    )
}

export default Table