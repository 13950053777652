import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import useFetchUser from '../CustomHook/FetchData/useFetchUser';
import Alert from './Shared/Alert';
import Loading from './Shared/Loading'
import { useSelector, useDispatch } from 'react-redux';
import { checkUserState, login } from '../redux/Functions/userReduxFunctions';
import { getApplications } from '../redux/Functions/applicationsReduxFunctions';
import { getBreadcrumbs } from '../redux/Functions/breadcrumbsReduxFunctions';
import { resetPassword } from '../redux/Functions/userReduxFunctions';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [showinfo, setShowinfo] = useState(false);
    const navigate = useNavigate();
    const { user } = useSelector(store => store);
    const dispatch = useDispatch();

    const { checkUser, userExists, isLoadingCheckUser } = useFetchUser();

    useEffect(() => {
        dispatch(checkUserState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isLoadingCheckUser && !userExists) {
            setAlertMessage("L'utente non è rigistrato a database; contattare il reparto IT.");
            setShowAlert(true);
            return;
        }

        if (!user.loggedIn) {
            switch (user.errorCode) {
                case null: return;
                case "auth/user-not-found":
                    setAlertMessage("Utente non censito in Firebase, contattare reparto IT");
                    setShowAlert(true);
                    return;
                case "auth/wrong-password":
                    setAlertMessage("Password errata, riprovare");
                    setShowAlert(true);
                    return;
                case "auth/too-many-requests":
                    setAlertMessage("Troppe tentativi di accesso andati in errore; attendere qualche minuto");
                    setShowAlert(true);
                    return;
                default:
                    setAlertMessage("Qualcosa è andato storto, contatta il reparto IT. Messaggio d'errore: " + user.errorCode);
                    setShowAlert(true);
                    return;
            }
        }

        dispatch(getApplications(user));
        dispatch(getBreadcrumbs(user));
        navigate("/home");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking, user.errorCode, isLoadingCheckUser])

    //check for reset password from navbar
    useEffect(() => {
        if (user.resetPassword.isResetPassword && user.resetPassword.isResetPasswordError === null) {
            setShowAlert(false);
            setInfoMessage("E' stata inviata una mail per effettuare il reset della password. Controlla anche nella casella di Spam o Posta Indesiderata");
            setShowinfo(true);
            return;
        }
        else if (!user.resetPassword.isResetPassword && user.resetPassword.isResetPasswordError !== null) {
            setShowinfo(false);
            setAlertMessage("Errore durante il reset della password; di seguito l'errore:\n" + user.resetPassword.isResetPasswordError);
            setShowAlert(true);
            return;
        }
    }, [user.resetPassword.isResetPassword, user.resetPassword.isResetPasswordError])

    const handleSubmit = (e) => {
        e.preventDefault();
        checkUser(email);
        dispatch(login(email, password));
    };

    return (
        user.checking ?
            <Loading />
            :
            <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="row mb-3 text-start">
                        <div className="col-12 col-lg-3"></div>
                        <div className="col-12 col-lg-6">
                            <h2 className='text-center'>Portale Stampa</h2>
                            <Alert type="danger" message={alertMessage} show={showAlert} />
                            <Alert type="info" message={infoMessage} show={showinfo} />
                            <div className="card mb-4 rounded-3 shadow-sm">
                                <div className="card-body">
                                    <h4 className="title-card-body my-0 fw-normal">Login</h4>
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" id="emailInput" required onChange={(e) => setEmail(e.target.value)} />
                                        <label htmlFor="emailInput">Email address</label>
                                    </div>
                                    <div className="form-floating">
                                        <input type="password" className="form-control" id="passwordInput" required onChange={(e) => setPassword(e.target.value)} />
                                        <label htmlFor="passwordInput">Password</label>
                                    </div>
                                    <br />
                                    <button type="submit" className="btn btn-lg btn-primary float-end">Login</button>
                                </div>
                            </div>
                            <div className='text-center'>
                                <Link onClick={() => { dispatch(resetPassword(email, false)); }}>Reset password</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </form >
    )
}

export default Login