import React, { useEffect } from 'react'
import useFetchUser from '../../CustomHook/FetchData/useFetchUser'
import Loading from './Loading'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Home = () => {
  const { user } = useSelector(store => store);
  const { getFunctions, functions, isLoading } = useFetchUser();
  const lastSegment = window.location.href.split("/").pop();

  //call getFunctions every change of user and applicationAlias
  useEffect(() => {
    if (user)
      getFunctions(lastSegment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, lastSegment]);
  return (
    <>
      <div className='container'>
        <br />
        <div className='row mb-3 text-start'>
          <ol className="list-group list-group-numbered">
            {
              !isLoading
                ?
                functions.map((el, index) => {
                  return (
                    <Link to={el.alias.toLowerCase()} key={index} >
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">{el.name}</div>
                          {el.description}
                        </div>
                      </li>
                    </Link>
                  )

                }
                )
                :
                <Loading />
            }
          </ol>
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  )
}

export default Home