import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useFetchSetup from '../../CustomHook/FetchData/useFetchSetup';
import InvoiceTypeEditModal from './InvoiceTypeEditModal';
import Loading from '../Shared/Loading';
import useSetup from '../../CustomHook/useSetup';

const InvoiceTypeAssociation = () => {
    const { user } = useSelector(store => store);
    const { setSupplyType, protocols, isLoadingProtocolList } = useFetchSetup();
    const { deleteProtocol, isDeletingProtocol, errorDeleteProtocol, addProtocol, isAddingProtocol, errorAddProtocol } = useSetup();

    const [protocolId, setProtocolId] = useState(undefined);
    const [filter, setFilter] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [soselProtocol, setSoselProtocol] = useState(undefined);

    useEffect(() => {
        if (!user.loggedIn)
            return;
        setSupplyType('AMM');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking])

    //#region delete protocol
    useEffect(() => {
        if (!isDeletingProtocol)
            if (errorDeleteProtocol !== undefined)
                alert("ERROR: " + errorDeleteProtocol)
            else {
                window.location.reload(false);
            }
    }, [isDeletingProtocol, errorDeleteProtocol])
    //#endregion

    //#region add protocol
    const handleSubmit = (e) => {
        e.preventDefault();
        var request = {
            filter: !filter ? null : filter,
            code: !code ? null : code,
            soselProtocol: !soselProtocol ? null : soselProtocol
        }
        console.log(request);
        console.log(!request.filter);
        addProtocol(request);
    }
    useEffect(() => {
        if (!isAddingProtocol)
            if (errorAddProtocol !== undefined)
                alert("ERROR: " + errorAddProtocol)
            else {
                window.location.reload(false);
            }
    }, [isAddingProtocol, errorAddProtocol])

    //#endregion
    return (
        <div className='container'>
            <div>
                <h3>Associa tipo fattura</h3>
            </div>
            {/* <form onSubmit={handleSubmit}> */}
            <form className='row g-3' onSubmit={handleSubmit}>
                <div className='col-md-3'>
                    <label className="form-label">Filtro</label>
                    <input className="form-control" type="text" aria-label="default input example" id="filter" onChange={(e) => setFilter(e.target.value.trim())} required />
                </div>
                <div className='col-md-3'>
                    <label className="form-label">Codice</label>
                    <input className="form-control" type="text" aria-label="default input example" id="code" onChange={(e) => setCode(e.target.value.trim())} required />
                </div>
                <div className='col-md-3'>
                    <label className="form-label">Protocollo Sosel</label>
                    <input className="form-control" type="text" aria-label="default input example" id="soselProtocol" onChange={(e) => setSoselProtocol(e.target.value.trim())} required />
                </div>
                <div className='col-md-3'></div>
                <div className='col-md-3'>
                    <button type="submit" className="btn btn-lg btn-primary" id="btnInvoiceListSearch" >Aggiungi protocollo</button>
                </div>

                <div className="table-responsive-lg">
                    {isLoadingProtocolList ?
                        <Loading />
                        :
                        <table className="table">
                            <thead>
                                <tr >
                                    <th scope="col">Filtro</th>
                                    <th scope="col">Codice</th>
                                    <th scope="col">Protocollo Sosel</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            {
                                protocols === undefined ?
                                    <tbody className='text-center'>
                                        <tr>
                                            <td colSpan="12">Nessun risultato</td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        {
                                            protocols.map((el, index) => {
                                                return (
                                                    <tr key={index} className="form-control-sm">

                                                        <td>{el.value}</td>
                                                        <td>{el.code}</td>
                                                        <td>{el.soselProtocol}</td>
                                                        <td className='text-center'>
                                                            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#invoiceTypeEdit" onClick={() => { setProtocolId(el.id) }}>
                                                                <FontAwesomeIcon icon="fa-light fa-pencil" size='xl' />
                                                            </button>

                                                        </td>
                                                        <td className='text-center'>
                                                            <FontAwesomeIcon icon="fa-light fa-trash-can" size='xl' style={{ cursor: "pointer" }} onClick={() => {
                                                                if (window.confirm("Vuoi davvero eliminare questo protocollo?"))
                                                                    deleteProtocol(el.id)
                                                            }} />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                            }
                        </table>
                    }
                    {/* <Alert message={errorMessage} type="danger" show={errorMessage !== ""} />
                        <Alert message={updateError} type="danger" show={updateError !== ""} /> */}
                </div>
            </form>
            <InvoiceTypeEditModal id={protocolId} />
            {/* </form> */}
        </div>
    )
}

export default InvoiceTypeAssociation