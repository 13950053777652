import axios from "axios";

export const instance = axios.create(
    {
        baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}`,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
    }
);

export const instanceDownload = axios.create(
    {
        baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}`,
        responseType: "arraybuffer",
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
    }
);