import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import useFetchInvoice from '../../../CustomHook/FetchData/useFetchInvoice';
import useFetchSetup from '../../../CustomHook/FetchData/useFetchSetup';
import useAzureFunction from '../../../CustomHook/useAzureFunction';

const Filters = ({ getSelectAll, getErrorCreation, getInvoiceData, currentPage, removeAllInvoiceToPrint, getInvoicesToPrintReq, setIsLoadingInvoices, setShowNotification, setIsError }) => {
    let { supplyTypeParam } = useParams();
    var supplyType;
    if (supplyTypeParam === "fattureelettrico")
        supplyType = "ee"
    else if (supplyTypeParam === "fatturegas")
        supplyType = "gas"
    const { user } = useSelector(store => store);

    //#region Custom Hook
    const { invoicePaginatedResp, getInvoicePaginatedReq, isLoading, getPageNumber, invoicePaginatedError } = useFetchInvoice();
    const { getSetupFlow, setSupplyType, protocols, paymentMethods } = useFetchSetup();
    const { setSyncInvoiceReq } = useAzureFunction();
    //#endregion

    //#region Variables
    var dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 7);
    var dateString = dateNow.getUTCFullYear() + "-" + ("0" + (dateNow.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + dateNow.getUTCDate()).slice(-2);
    //only for debug
    // dateString = "2021-10-01";
    //#endregion

    //#region get the protocols by supply type
    useEffect(() => {
        if (!user.loggedIn)
            return;
        getSetupFlow();
        setSupplyType(supplyType);
        getInvoicePaginatedReq({
            invoiceDateFrom: dateString,
            serviceType: supplyType
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking])
    //#endregion

    //#region use effect
    useEffect(() => {
        getInvoiceData(invoicePaginatedResp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicePaginatedResp])

    useEffect(() => {
        getPageNumber(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])
    //#endregion

    //#region Handle submit form
    const handleSubmit = (e) => {
        e.preventDefault();
        document.getElementById("selectAllCheckbox").checked = false;
        document.getElementById("selectAllLabel").textContent = "Seleziona tutto";
        var checkboxes = document.getElementsByName('flexCheckDefault');
        if (checkboxes.length > 0) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
        }
        getSelectAll(false);
        removeAllInvoiceToPrint();
        const search = getSearchParameters();
        getInvoicePaginatedReq(search);
        getErrorCreation(search.errorCreation);
        setShowNotification(false);
    };
    //#endregion

    //#region Local Functions
    const getSearchParameters = () => {
        return {
            invoiceNumber: document.getElementById("invoiceNumber").value === "" ? undefined : document.getElementById("invoiceNumber").value,
            invoiceNumberFrom: document.getElementById("invoiceNumberFrom").value === "" ? undefined : document.getElementById("invoiceNumberFrom").value,
            invoiceNumberTo: document.getElementById("invoiceNumberTo").value === "" ? undefined : document.getElementById("invoiceNumberTo").value,
            customerCode: document.getElementById("customerCode").value === "" ? undefined : document.getElementById("customerCode").value,
            invoiceDateFrom: document.getElementById("invoiceDateFrom").value === "" ? undefined : document.getElementById("invoiceDateFrom").value,
            invoiceDateTo: document.getElementById("invoiceDateTo").value === "" ? undefined : document.getElementById("invoiceDateTo").value,
            invoiceExpiredDateFrom: document.getElementById("expiryDateFrom").value === "" ? undefined : document.getElementById("expiryDateFrom").value,
            invoiceExpiredDateTo: document.getElementById("expiryDateTo").value === "" ? undefined : document.getElementById("expiryDateTo").value,
            paymentMethod: document.getElementById("paymentMethod").value === "" ? undefined : document.getElementById("paymentMethod").value,
            mavCode: document.getElementById("mavCode").value === "" ? undefined : document.getElementById("mavCode").value,
            protocol: document.getElementById("protocol").value === "" ? undefined : document.getElementById("protocol").value,
            pdfPresent: document.getElementById("pdfPresent").value === "-1" ? undefined : JSON.parse(document.getElementById("pdfPresent").value),
            sentSosel: document.getElementById("sentSosel").value === "" ? undefined : JSON.parse(document.getElementById("sentSosel").value),
            errorCreation: document.getElementById("errorCreation").value === "" ? undefined : JSON.parse(document.getElementById("errorCreation").value),
            flowNumber: document.getElementById("flowNumber").value === "" ? undefined : document.getElementById("flowNumber").value,
            inFlow: document.getElementById("inFlow").value === "" ? undefined : JSON.parse(document.getElementById("inFlow").value),
            serviceType: supplyType
        }
    }
    //#endregion

    //#region Notification
    useEffect(() => {
        setIsLoadingInvoices(isLoading);
        if (!isLoading)
            setIsError(invoicePaginatedError)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])
    //#endregion

    //#region selectAllCheckbox handle
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            getSelectAll(true);
            getInvoicesToPrintReq(getSearchParameters());
        }
        else {
            getSelectAll(false);
            removeAllInvoiceToPrint();
        }
        setShowNotification(true);
    }
    const handleOnChange = (e) => {
        if (e.target.checked) { document.getElementById("selectAllLabel").textContent = "Deseleziona tutto"; }
        else { document.getElementById("selectAllLabel").textContent = "Seleziona tutto"; }
    }
    //#endregion

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset className='row g-3' disabled={isLoading}>
                    <div className='col-md-2'>
                        <label className="form-label">Numero fattura</label>
                        <input className="form-control" type="text" aria-label="default input example" id="invoiceNumber" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Numero fattura (da)</label>
                        <input className="form-control" type="text" aria-label="default input example" id="invoiceNumberFrom" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Numero fattura (a)</label>
                        <input className="form-control" type="text" aria-label="default input example" id="invoiceNumberTo" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Codice cliente</label>
                        <input className="form-control" type="text" aria-label="default input example" id="customerCode" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Data fattura (da)</label>
                        <input className="form-control" type="date" aria-label="default input example" id="invoiceDateFrom" defaultValue={dateString} />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Data fattura (a)</label>
                        <input className="form-control" type="date" aria-label="default input example" id="invoiceDateTo" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Data scadenza (da)</label>
                        <input className="form-control" type="date" aria-label="default input example" id="expiryDateFrom" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Data scadenza (a)</label>
                        <input className="form-control" type="date" aria-label="default input example" id="expiryDateTo" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Metodo di pagamento</label>
                        <select className="form-select" aria-label="Default select example" id="paymentMethod" >
                            <option value="">Tutti...</option>
                            {
                                paymentMethods.map((el, index) => {
                                    return <option key={index} value={el}>{el}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Codice MAV</label>
                        <input className="form-control" type="text" aria-label="default input example" id="mavCode" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Protocollo</label>
                        <select className="form-select" aria-label="Default select example" id="protocol" >
                            <option value="">Tutti...</option>
                            {
                                protocols.map((el, index) => {
                                    return <option key={index} value={el.value}>{el.description}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">PDF Esistente</label>
                        <select className="form-select" aria-label="Default select example" id="pdfPresent" >
                            <option value="-1">Tutti...</option>
                            <option value="false">Senza PDF</option>
                            <option value="true">Con PDF</option>
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Inviate a SOSEL</label>
                        <select className="form-select" aria-label="Default select example" id="sentSosel" >
                            <option value="">Tutte...</option>
                            <option value="false">No</option>
                            <option value="true">Sì</option>
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">In errore</label>
                        <select className="form-select" aria-label="Default select example" id="errorCreation" >
                            <option value="">Tutte...</option>
                            <option value="false">No</option>
                            <option value="true">Sì</option>
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Numero flusso</label>
                        <input className="form-control" type="text" aria-label="default input example" id="flowNumber" />
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">Non in flusso</label>
                        <select className="form-select" aria-label="Default select example" id="inFlow" >
                            <option value="">Tutte...</option>
                            <option value="false">Non in flusso</option>
                            <option value="true">In flusso</option>
                        </select>
                    </div>
                    <div className='col-md-4'></div>
                    <div className='col-md-2'>
                        <button type="submit" className="btn btn-lg btn-primary" id="btnInvoiceListSearch">Cerca</button>
                    </div>
                    <div className='col-md-2 form-check d-flex align-items-center'>
                        <input className="form-check-input" type="checkbox" id="selectAllCheckbox" onClick={handleSelectAll} onChange={handleOnChange} />&nbsp;&nbsp;&nbsp;
                        <label className="form-check-label text-secondary" id='selectAllLabel'>
                            Seleziona tutto
                        </label>
                    </div>
                    <div className='col-md-3'>
                        {/* <button className="btn btn-lg btn-primary" >Sincronizza tabella</button> */}
                        <button className="btn btn-lg btn-primary" onClick={() => { alert("Riceverete una mail quando la sincronizzazione sarà conclusa."); setSyncInvoiceReq({ supplyType: supplyType, email: null }) }} >Sincronizza tabella</button>
                    </div>
                </fieldset>
            </form>
        </>
    )
}

export default Filters