import React, { useEffect } from 'react'
import Loading from '../../../Shared/Loading'

const RolesModal = ({ userUnassignedRoles, isLoadingUnassignedRoles, setRoleIdToAdd, isAddingUserRole }) => {
    useEffect(() => {
        if (!isAddingUserRole)
            document.getElementById('closeModalButton').click();
    }, [isAddingUserRole])
    return (
        <div className="modal fade" id="assignRoleUserModal" tabIndex="-1" aria-labelledby="assignRoleUserModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="assignRoleUserModalLabel">Dettaglio
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='closeModalButton'></button>
                    </div>
                    <>
                        {
                            isLoadingUnassignedRoles ?
                                <Loading />
                                :
                                <div className="table-responsive-lg">
                                    <table className="table">
                                        <thead>
                                            <tr >
                                                <th scope="col">Nome</th>
                                                <th scope="col">Descrizione</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        {
                                            userUnassignedRoles === undefined ?
                                                <tbody className='text-center'>
                                                    <tr>
                                                        <td colSpan="12">Nessun risultato</td>
                                                    </tr>
                                                </tbody>
                                                :
                                                <tbody>
                                                    {
                                                        userUnassignedRoles.map((el, index) => {
                                                            return (
                                                                <tr key={index} className="form-control-sm" valign="middle">
                                                                    <td>{el.name}</td>
                                                                    <td>{el.description}</td>
                                                                    <td>
                                                                        <button type='button' className='btn btn-primary' onClick={() => { setRoleIdToAdd(el.id) }}>Associa Ruolo</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                        }
                                    </table>
                                </div>
                        }
                    </>

                    <div className="modal-footer">
                        <button type="button" id="closeModalButton" className="btn btn-primary" data-bs-dismiss="modal">Chiudi</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RolesModal