import React from 'react'
import Filters from './Filters';
import Table from './Table';
import NewFunctionModal from './NewFunctionModal';
import useFetchAdmin from '../../../CustomHook/FetchData/useFetchAdmin';
import useFetchFunction from '../../../CustomHook/FetchData/useFetchFunction';

const Home = () => {

    const { getApplications, applications, isLoading } = useFetchAdmin();
    const { setFunctionReq, functions, isLoadingFunction } = useFetchFunction();
    return (
        <div className='container'>
            <Filters setFunctionReq={setFunctionReq} getApplications={getApplications} applications={applications} isLoading={isLoading} />
            <br />
            <Table functions={functions} isLoadingFunctions={isLoadingFunction} />
            <NewFunctionModal setFunctionReq={setFunctionReq} />
            <button type='button' className='btn btn-primary float-end' data-bs-toggle="modal" data-bs-target="#newFunctionModal">Aggiungi funzione</button>
            <br />
            <br />
            <br />
        </div>
    )
}

export default Home