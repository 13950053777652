import React, { useState } from 'react'
import Loading from '../Shared/Loading';
import useFile from '../../CustomHook/useFile';
import Alert from '../Shared/Alert';

const AbNow = () => {
    const { setBodyAbNow, isLoadingAbNow, isErrorAbNow, errorMessageAbNow } = useFile();
    const [fileName, setFileName] = useState();
    const [file, setFile] = useState();

    const changeHandler = (e) => {
        if (e.target.files[0] && e.target.files) {
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name)
        }
    }
    const handleSubmit = () => {
        if (file === undefined && fileName === undefined)
            return;
        const formData = new FormData();
        formData.append('formFile', file);
        setBodyAbNow(formData);
        setFileName();
    }

  return (
    <div className='container'>
            {
                isLoadingAbNow ?
                    <Loading />
                    :
                    <>
                        <h2>CLIENTI ABNOW</h2>
                        <div className="mb-3">
                            <label htmlFor="redZoneFile" className="form-label">File input</label>
                            <input className="form-control" type="file" id="redZoneFile" accept='.xlsx' onChange={changeHandler} />
                        </div>
                        <div className="mb-3">
                            <button className="btn btn-lg btn-primary" disabled={fileName === undefined} onClick={() => { handleSubmit() }}>Carica File</button>
                        </div>
                        <Alert type="danger" message={errorMessageAbNow} show={isErrorAbNow} />
                        <Alert type="success" message="File caricato correttamente" show={isErrorAbNow === false} />
                    </>
            }

        </div>
  )
}

export default AbNow