/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { instance } from "../app"
import { useSelector } from 'react-redux';


const useFetchSetup = () => {
    const [isLoadingSetup, setIsLoadingSetup] = useState(true);
    const [protocols, setProtocols] = useState([]);
    const [isLoadingProtocolList, setIsLoadingProtocolList] = useState(true);
    const [supplyType, setSupplyType] = useState("");
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [creationStatus, setCreationStatus] = useState([]);
    const [uploadStatus, setUploadStatus] = useState([]);
    const [importationStatus, setImportationStatus] = useState([]);
    const [accountingCompanies, setAccountingCompanies] = useState([]);
    const [creationStatusAccounting, setCreationStatusAccounting] = useState([]);
    const [uploadStatusAccounting, setUploadStatusAccounting] = useState([]);
    const [importationStatusAccounting, setImportationStatusAccounting] = useState([]);
    const [protocolId, setProtocolId] = useState("");
    const [protocolResp, setProtocolResp] = useState(undefined);
    const [isLoadingProtocol, setIsLoadingProtocol] = useState(true);

    const { user } = useSelector(store => store);

    //#region Protocols
    const getProtocols = async () => {
        try {
            if (user.email === null) {
                return null;
            }
            if (supplyType === "")
                return null;
            setIsLoadingProtocolList(true);
            var response = await instance.get(`/setup/protocols/${supplyType}`);

            setProtocols(response.data);
            setIsLoadingProtocolList(false);
        } catch (error) {
            setIsLoadingProtocolList(false);
            console.log("error: " + error);
        }
    }

    useEffect(() => {
        getProtocols();
    }, [supplyType]);
    //#endregion

    //#region get protocol
    const getProtocol = async () => {
        try {
            if (user.email === null) {
                return null;
            }
            if (protocolId === undefined || protocolId === "")
                return null;
            setIsLoadingProtocol(true);
            var response = await instance.get(`/setup/protocols/${protocolId}`);

            setProtocolResp(response.data);
            setIsLoadingProtocol(false);

        } catch (error) {
            setIsLoadingProtocol(false);
            console.log("error: " + error);
        }


    }
    useEffect(() => {
        getProtocol();
    }, [protocolId]);
    //#endregion


    //#region payment methods
    const getPaymentMethods = async () => {
        try {
            if (!user.loggedIn)
                return null;

            var response = await instance.get(`/setup/payment-methods`);

            setPaymentMethods(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    // useEffect(() => { getPaymentMethods() }, [])
    //#endregion

    //#region creation status
    const getCreationStatus = async () => {
        try {
            if (!user.loggedIn)
                return null;

            //setIsLoadingSetup(true);
            var response = await instance.get(`/setup/creation-status`);

            setCreationStatus(response.data);
        } catch (error) {
            console.error(error);
        }
        //setIsLoadingSetup(false);
    }
    //#endregion

    //#region upload status
    const getUploadStatus = async () => {
        try {
            if (!user.loggedIn)
                return null;
            //setIsLoadingSetup(true);

            var response = await instance.get(`/setup/upload-status`);

            setUploadStatus(response.data);
        } catch (error) {
            console.error(error);
        }
        //setIsLoadingSetup(false);
    }
    //#endregion

    //#region importation status
    const getImportationStatus = async () => {
        try {
            if (!user.loggedIn)
                return null;

            //setIsLoadingSetup(true);
            var response = await instance.get(`/setup/importation-status`);

            setImportationStatus(response.data);
        } catch (error) {
            console.error(error);
        }
        //setIsLoadingSetup(false);
    }
    //#endregion

    //#region get accounting companies
    const getAccountingCompanies = async () => {
        try {
            if (!user.loggedIn)
                return null;

            //setIsLoadingSetup(true);
            var response = await instance.get(`/setup/accounting/companies`);

            setAccountingCompanies(response.data);
        } catch (error) {
            console.error(error);
        }
        //setIsLoadingSetup(false);
    }
    // useEffect(() => { getAccountingCompanies() }, [])
    //#endregion

    //#region creation status accounting
    const getCreationStatusAccounting = async () => {
        try {
            if (!user.loggedIn)
                return null;

            //setIsLoadingSetup(true);
            var response = await instance.get(`/setup/accounting/creation-status`);

            setCreationStatusAccounting(response.data);
        } catch (error) {
            console.error(error);
        }
        //setIsLoadingSetup(false);
    }
    //#endregion

    //#region upload status accounting
    const getUploadStatusAccounting = async () => {
        try {
            if (!user.loggedIn)
                return null;

            //setIsLoadingSetup(true);
            var response = await instance.get(`/setup/accounting/upload-status`);

            setUploadStatusAccounting(response.data);
        } catch (error) {
            console.error(error);
        }
        //setIsLoadingSetup(false);
    }
    //#endregion

    //#region importation status accounting
    const getImportationStatusAccounting = async () => {
        try {
            if (!user.loggedIn)
                return null;

            //setIsLoadingSetup(true);
            var response = await instance.get(`/setup/accounting/importation-status`);

            setImportationStatusAccounting(response.data);
        } catch (error) {
            console.error(error);
        }
        //setIsLoadingSetup(false);
    }
    //#endregion

    //#region get setup flow
    const getSetupFlow = () => {
        setIsLoadingSetup(true);
        getCreationStatus();
        getUploadStatus();
        getImportationStatus();
        getCreationStatusAccounting();
        getUploadStatusAccounting();
        getImportationStatusAccounting();
        getPaymentMethods();
        getAccountingCompanies();
        setIsLoadingSetup(false);
    }
    //#endregion

    return {
        setSupplyType, getSetupFlow, setProtocolId, protocols, paymentMethods, creationStatus, uploadStatus, importationStatus, isLoadingSetup,
        accountingCompanies, creationStatusAccounting, uploadStatusAccounting, importationStatusAccounting, protocolResp, isLoadingProtocol, isLoadingProtocolList
    }
}

export default useFetchSetup;
