import { useEffect, useState } from 'react'
import { instance } from './app';
import { useSelector } from 'react-redux';

const useAdmin = () => {
    const { user } = useSelector(store => store);
    const [application, setApplication] = useState(undefined);
    const [isSaving, setIsSaving] = useState(true);
    const [error, setError] = useState("");
    const [newApplication, setNewApplication] = useState(undefined);

    //#region update Application
    const updateApplication = async () => {
        try {
            setIsSaving(true);
            if (!user.loggedIn || application === undefined)
                return;
            var response = await instance.patch(`/admin/application`, application);
            if (response.status !== 200)
                setError("Response status was not 200, here the response: " + response.message);
        } catch (error) {
            console.error(error);
            setError(error.response.data.error.message);
        }
        setIsSaving(false);
    }
    //#endregion

    //#region insert Application
    const insertApplication = async () => {
        try {
            setIsSaving(true);
            if (!user.loggedIn || newApplication === undefined)
                return;
            var response = await instance.post(`/admin/application`, newApplication);
            if (response.status !== 200)
                setError("Response status was not 200, here the response: " + response.message);
        } catch (error) {
            console.error(error);
            setError(error.response.data.error.message);
        }
        setIsSaving(false);
    }
    //#endregion

    useEffect(() => {
        updateApplication();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application])

    useEffect(() => {
        insertApplication();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newApplication])

    return {
        setApplication,
        setNewApplication,
        isSaving,
        error
    }
}


export default useAdmin