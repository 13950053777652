import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import UserDetail from './Detail/UserDetail';
import RolesDetail from './Detail/RolesDetail';
import RolesModal from './Detail/RolesModal'
import useFetchUser from '../../../CustomHook/FetchData/useFetchUser';
import { useSelector } from 'react-redux';
import useUser from '../../../CustomHook/useUser';

const Detail = () => {
    let { userId } = useParams();
    const { user } = useSelector(store => store);

    const { setUserId, getUserRoles, getUserUnassignedRoles, userRoles, userDetailResp, userUnassignedRoles, isLoadingUserRoles, isLoadingUnassignedRoles, isLoadingUserDetail } = useFetchUser();
    const { setDeleteUserRoleReq, setAddUserRoleReq, isDeletingUserRole, isAddingUserRole } = useUser();

    const [roleIdToDelete, setRoleIdToDelete] = useState(undefined);
    const [roleIdToAdd, setRoleIdToAdd] = useState(undefined);

    useEffect(() => {
        if (user.loggedIn)
            setUserId(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, user.checking])

    //#region Delete user role and reload
    useEffect(() => {
        setDeleteUserRoleReq({
            roleId: roleIdToDelete,
            userId: userId
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleIdToDelete]);

    useEffect(() => {
        if (!isDeletingUserRole) {
            getUserUnassignedRoles();
            getUserRoles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeletingUserRole])
    //#endregion

    //#region Add user role and reload
    useEffect(() => {
        setAddUserRoleReq({
            roleId: roleIdToAdd,
            userId: userId
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleIdToAdd]);

    useEffect(() => {
        if (!isAddingUserRole) {
            getUserRoles();
            getUserUnassignedRoles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingUserRole])
    //#endregion

    return (
        <>
            <UserDetail userDetailResp={userDetailResp} isLoadingUserDetail={isLoadingUserDetail} />
            <RolesDetail userRoles={userRoles} isLoadingUserRoles={isLoadingUserRoles} setRoleIdToDelete={setRoleIdToDelete} />
            <RolesModal userUnassignedRoles={userUnassignedRoles} isLoadingUnassignedRoles={isLoadingUnassignedRoles} setRoleIdToAdd={setRoleIdToAdd} isAddingUserRole={isAddingUserRole} />
        </>
    )
}

export default Detail