import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';

export default function Breadcrumbs() {
    const { breadcrumbs } = useSelector(store => store);
    const location = useLocation();

    if (breadcrumbs.loading)
        return;
    if (location.pathname === "/home" || location.pathname === "/" || location.pathname.toLowerCase() === "/resetpassword")
        return <></>;
    let currentLink = '';
    let finalCrumb = '';

    const crumbs = location.pathname.split('/')
        .filter(crumb => crumb !== '')
        .map(crumb => {
            currentLink += `/${crumb}`
            finalCrumb = breadcrumbs.data.reduce((a, b) => {
                return a.replaceAll(b.crumbString, b.stringShowed);
            }, crumb);
            if (currentLink === location.pathname)
                return (
                    <li className="breadcrumb-item active" aria-current="page" key={crumb}>{finalCrumb} </li>
                )
            return (
                <li className="breadcrumb-item" key={crumb}><Link to={currentLink}>{finalCrumb}</Link></li>
            )
        })

    return (
        <div className='container'>
            <h2>{finalCrumb}</h2>
            <nav aria-label="breadcrumb">
                <style>{`--bs-breadcrumb-divider: '>';`}</style>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                    {crumbs}
                </ol>
            </nav>
        </div>
    )
}
