import React from 'react'
import Filters from './Filters'
import Table from './Table';
import NewUserModal from './NewUserModal';
import useFetchUser from '../../../CustomHook/FetchData/useFetchUser';

const Home = () => {
    const { setUserReq, userResp, isLoadingUser } = useFetchUser();

    return (
        <div className='container'>
            <Filters setUserReq={setUserReq} />
            <Table users={userResp} isLoading={isLoadingUser} />
            <button type='button' className='btn btn-primary float-end' data-bs-toggle="modal" data-bs-target="#newUserModal">Aggiungi utente</button>
            <br />
            <br />
            <br />
            <NewUserModal setUserReq={setUserReq}/>
        </div>
    )
}

export default Home