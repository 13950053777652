import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import HomeInvoiceFlow from "./components/InvoiceFlow/Home";
import InvoiceList from "./components/InvoiceFlow/Billing/InvoiceList";
import FlowList from "./components/InvoiceFlow/Flow/FlowList";
import InvoiceListAccounting from "./components/InvoiceFlow/Accounting/InvoiceList";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import Login from "./components/Login"
import Navbar from "./components/Shared/Navbar";
import Breadcrumbs from "./components/Shared/Breadcrumbs";
import RaiFee from "./components/Services/RaiFee";
import BonusEe from "./components/Services/BonusEe";
import AbNow from "./components/Services/AbNow";
import HomeServices from "./components/Shared/Home";
import ClimaticZone from "./components/Services/ClimaticZone/Home";
import AdminHome from './components/Shared/Home'
import ApplicationsHome from "./components/Admin/Applications/Home";
import FunctionHome from "./components/Admin/Functions/Home"
import FunctionDetail from "./components/Admin/Functions/Detail";
import UserHome from './components/Admin/Users/Home'
import UserDetail from './components/Admin/Users/Detail'
import ApplibraCall from "./components/Services/ApplibraCall";
import { Provider } from "react-redux";
import store from "./redux/store";
import PrivateRoute from "./auth/PrivateRoute";
import InvoiceTypeAssociation from "./components/Services/InvoiceTypeAssociation";
import NavSessions from "./components/Services/NavSessions";

function App() {
    return (
        <Provider store={store}>
            <div className="app">
                <Router>
                    <Navbar />
                    <div style={{ marginBottom: '100px' }} />
                    <ReactNotifications />
                    <Breadcrumbs />
                    <Routes>
                        <Route path="/" exact index element={<Login />} action />
                        <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
                        <Route path="/flussifatture" element={<PrivateRoute><HomeInvoiceFlow /></PrivateRoute>} />
                        <Route path="/flussifatture/:supplyTypeParam" element={<PrivateRoute><InvoiceList /></PrivateRoute>} />
                        <Route path="/flussifatture/flussifatturazione" element={<PrivateRoute><FlowList /></PrivateRoute>} />
                        <Route path="/flussifatture/amministrazioneclienti" element={<PrivateRoute><InvoiceListAccounting /></PrivateRoute>} />
                        <Route path="/flussifatture/amministrazioneflussi" element={<PrivateRoute><FlowList /></PrivateRoute>} />
                        <Route path="/servizi" element={<PrivateRoute><HomeServices /></PrivateRoute>} />
                        <Route path="/servizi/SessioniNavFat" element={<PrivateRoute><NavSessions /></PrivateRoute>} />
                        <Route path="/servizi/clienticanonerai" element={<PrivateRoute><RaiFee /></PrivateRoute>} />
                        <Route path="/servizi/bonuselettrico" element={<PrivateRoute><BonusEe /></PrivateRoute>} />
                        <Route path="/servizi/abnow" element={<PrivateRoute><AbNow /></PrivateRoute>} />
                        <Route path="/servizi/zoneclimatiche" element={<PrivateRoute><ClimaticZone /></PrivateRoute>} />
                        <Route path="/servizi/chiamataapplibra" element={<PrivateRoute><ApplibraCall /></PrivateRoute>} />
                        <Route path="/servizi/amministrazioneassociatipofattura" element={<PrivateRoute><InvoiceTypeAssociation /></PrivateRoute>} />
                        <Route path="/admin" element={<PrivateRoute><AdminHome /></PrivateRoute>} />
                        <Route path="/admin/applicativi" element={<PrivateRoute><ApplicationsHome /></PrivateRoute>} />
                        <Route path="/admin/funzioni" element={<PrivateRoute><FunctionHome /></PrivateRoute>} />
                        <Route path="/admin/funzioni/:functionId" element={<PrivateRoute><FunctionDetail /></PrivateRoute>} />
                        <Route path="/admin/utenti" element={<PrivateRoute><UserHome /></PrivateRoute>} />
                        <Route path="/admin/utenti/:userId" element={<PrivateRoute><UserDetail /></PrivateRoute>} />
                    </Routes>
                </Router>
            </div>
        </Provider>
    );
}

export default App;