import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const Filters = ({ setFunctionReq, getApplications, applications, isLoading }) => {
    // const { getApplications, applications, isLoading } = useFetchAdmin();
    // const { setFunctionReq, functions, isLoadingFunction } = useFetchFunction();
    const [applicationId, setApplicationId] = useState("");
    const [functionName, setFunctionName] = useState("");
    const { user } = useSelector(store => store);

    useEffect(() => {
        if (user.loggedIn) {
            getApplications();
            setFunctionReq({ applicationId: applicationId, functionName: functionName });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking])
    const handleSubmit = (e) => {
        e.preventDefault();
        setFunctionReq({ applicationId: applicationId, functionName: functionName })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                {
                    !isLoading ?
                        <>
                            <div className='col-md-3'>
                                <label className="form-label">Applicativo</label>
                                <select className="form-select" onChange={(e) => setApplicationId(e.target.value)} >
                                    <option value="">Tutti gli applicativi...</option>
                                    {
                                        applications.map((el, index) => {
                                            return <option key={index} value={el.id}>{el.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-8'>
                                <label className="form-label">Nome funzione</label>
                                <input className="form-control" type="text" placeholder='Nome della funzione' onChange={(e) => setFunctionName(e.target.value)} />
                            </div>
                            <div className='col-md-1 d-flex align-items-bottom' >
                                <button type='submit' className="btn btn-primary" >Cerca</button>
                            </div>
                        </>
                        :
                        <></>
                }

            </div>
        </form>
    )
}

export default Filters