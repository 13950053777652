/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { instance } from "./app";
import { useSelector } from 'react-redux';

const useSetup = () => {
    const { user } = useSelector(store => store);

    const [isAddingProtocol, setIsAddingProtocol] = useState(true);
    const [errorAddProtocol, setErrorAddProtocol] = useState(undefined);

    const [isSavingProtocol, setIsSavingProtocol] = useState(true);
    const [updateProtocolReq, setUpdateProtocolReq] = useState(undefined);
    const [errorUpdateProtocol, setErrorUpdateProtocol] = useState(undefined);

    const [isDeletingProtocol, setIsDeletingProtocol] = useState(true);
    const [errorDeleteProtocol, setErrorDeleteProtocol] = useState(undefined);

    //#region add protocol
    const addProtocol = async (request) => {
        try {
            setIsAddingProtocol(true);
            setErrorAddProtocol(undefined);
            if (!user.loggedIn || request === undefined || request === null)
                return;
            var response = await instance.post(`/setup/protocols`, request);
            if (response.status !== 200)
                setErrorAddProtocol("Response status was not 200, here the response: " + response.message);
        } catch (error) {
            setIsAddingProtocol(false);
            console.error(error);
            setErrorAddProtocol(error.response.data.title ?? error.response.data.error.message);
        }
        setIsAddingProtocol(false);
    }
    //#endregion

    //#region update protocol
    const updateProtocol = async () => {
        try {
            setIsSavingProtocol(true);
            setErrorUpdateProtocol(undefined);
            if (!user.loggedIn || updateProtocolReq === undefined)
                return;
            var response = await instance.patch(`/setup/protocols`, updateProtocolReq);
            if (response.status !== 200)
                setErrorUpdateProtocol("Response status was not 200, here the response: " + response.message);
        } catch (error) {
            setIsSavingProtocol(false);
            console.error(error);
            setErrorUpdateProtocol(error.response.data.title ?? error.response.data.error.message);
        }
        setIsSavingProtocol(false);
    }
    useEffect(() => {
        updateProtocol();
    }, [updateProtocolReq]);
    //#endregion

    //#region delete protocol
    const deleteProtocol = async (protocolId) => {
        try {
            setIsDeletingProtocol(true);
            setErrorDeleteProtocol(undefined);
            if (!user.loggedIn || protocolId === undefined)
                return;
            var response = await instance.delete(`/setup/protocols/${protocolId}`);
            if (response.status !== 200)
                setErrorDeleteProtocol("Response status was not 200, here the response: " + response.message);

        } catch (error) {
            setIsDeletingProtocol(false);
            console.error(error);
            setErrorDeleteProtocol(error.response.data.title ?? error.response.data.error.message);
        }
        setIsDeletingProtocol(false);
    }
    //#endregion

    return {
        setUpdateProtocolReq, isSavingProtocol, errorUpdateProtocol, deleteProtocol, isDeletingProtocol, errorDeleteProtocol, addProtocol, isAddingProtocol, errorAddProtocol
    }
}

export default useSetup
