import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux';
import useAzureFunction from '../../../CustomHook/useAzureFunction'

const TableAccounting = ({ data }) => {
    const { user } = useSelector(store => store);
    const { setUploadFlowReq } = useAzureFunction();
    return (
        <div className="table-responsive-lg">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Nome Flusso</th>
                        <th scope="col">Società</th>
                        <th scope="col">Fatt.</th>
                        <th scope="col">Fatt.OK</th>
                        <th scope="col" className='text-center'>Stato Creazione</th>
                        <th scope="col" className='text-center'>Stato Upload</th>
                        <th scope="col" className='text-center'>Utente Creazione</th>
                        <th scope="col" className='text-center'>Invio Cliente</th>
                        <th scope="col" className='text-center'>Fatt. Elett.</th>
                        <th scope="col">Up</th>
                    </tr>
                </thead>
                {
                    data.items === undefined ?
                        <tbody className='text-center'>
                            <tr>
                                <td colSpan="12">Nessun risultato</td>
                            </tr>
                        </tbody>
                        :
                        <tbody>
                            {
                                data.items.map((el, index) => {
                                    return (
                                        <tr key={index} className="form-control-sm">
                                            <th scope="row"></th>
                                            <td>{el.name}</td>
                                            <td>{el.company}</td>
                                            <td className='text-center'>{el.invoicesNumber}</td>
                                            <td className='text-center'>{el.invoicesNumberOK}</td>
                                            <td className='text-center'>
                                                {(() => {
                                                    switch (el.idCreation.toUpperCase()) {
                                                        case "C235ED0E-3464-4E54-B985-0216529609B0":
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'green' }} />
                                                        case "A1517AC4-1B9C-44C1-9E02-A79B8589678A":
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'red' }} />
                                                        case "85BFE277-0943-45ED-B8ED-B27B355C7511":
                                                            return <FontAwesomeIcon icon="fa-solid fa-cog" spin />
                                                        default:
                                                            return <></>
                                                    }
                                                })()}
                                            </td>
                                            <td className='text-center'>
                                                {(() => {
                                                    var idUpload = typeof el.idUpload === typeof '' ? el.idUpload.toUpperCase() : '';
                                                    switch (idUpload) {
                                                        case "9B12C6D7-F70A-4C03-965B-3FF9875E6B07":
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'green' }} />
                                                        case "B8302FE2-4BF2-4BF1-BFDD-ADA624E6330D":
                                                            return <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: 'red' }} />
                                                        default:
                                                            return <></>
                                                    }
                                                })()}
                                            </td>
                                            <td>{el.user}</td>
                                            <td className='text-center'>{el.send ? "SI" : "NO"}</td>
                                            <td className='text-center'>{el.electronicInvoicing ? "SI" : "NO"}</td>
                                            <td className='text-center'>
                                                {(() => {
                                                    var idCreation = typeof el.idCreation === typeof '' ? el.idCreation.toUpperCase() : '';
                                                    if (idCreation === "C235ED0E-3464-4E54-B985-0216529609B0" && el.idUpload === null)
                                                        return <FontAwesomeIcon icon="fa-light fa-cloud-arrow-up" size='xl' onClick={() => { alert("Verrà inviata una mail quando l'upload sarà terminato"); setUploadFlowReq({ flowName: el.name, email: user.email }) }} style={{ cursor: "pointer" }} />
                                                    else
                                                        return <></>
                                                })()}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                }
            </table>
        </div>
    )
}

export default TableAccounting