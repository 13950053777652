import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    checking: true,
    email: null,
    loggedIn: false,
    resetPassword: {
        isResetPassword: false,
        isResetPasswordError: null
    },
    errorCode: null
}

const userReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        setActiveUser: (state, action) => {
            state.email = action.payload;
            state.loggedIn = true;
            state.checking = false;
            state.errorCode = null;
            state.resetPassword.isResetPassword = false;
            state.resetPassword.isResetPasswordError = null;
        },
        setUserLogOutState: state => {
            state.email = null;
            state.loggedIn = false;
            state.checking = false;
            state.errorCode = null;
        },
        setLoginError: (state, action) => {
            state.email = null;
            state.loggedIn = false;
            state.checking = false;
            state.errorCode = action.payload;
        },
        setCheckingUser: (state) => {
            state.checking = true;
        },
        setPasswordResetSuccess: (state, action) => {
            state.resetPassword.isResetPassword = true;
            state.resetPassword.isResetPasswordError = null;
            if (action.payload.logoutUser) {
                state.email = null;
                state.loggedIn = false;
                state.checking = false;
                state.errorCode = null;
            }
        },
        setPasswordResetFail: (state, action) => {
            state.resetPassword.isResetPassword = false;
            state.resetPassword.isResetPasswordError = action.payload.errorMessage;
            if (action.payload.logoutUser) {
                state.email = null;
                state.loggedIn = false;
                state.checking = false;
                state.errorCode = null;
            }
        }
    }
});

export const { setActiveUser, setUserLogOutState, setLoginError, setCheckingUser, setPasswordResetSuccess, setPasswordResetFail } = userReducer.actions;

export default userReducer.reducer;