import React, { useEffect } from 'react'

const InvoiceAccountingTable = ({ data, addInvoiceToPrint, removeInvoiceToPrint, selectAll, setShowNotification }) => {

  //#region useEffect
  useEffect(() => {
    if (data.items !== undefined) {
      var checkboxes = document.getElementsByName('flexCheckDefault');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = selectAll;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  //#endregion

  //#region handleCheckbox
  const handleCheckbox = (e) => {
    const valueSplitted = e.target.value.split("#");
    const obj = { invoiceNumber: valueSplitted[0], protocol: valueSplitted[1] };
    if (e.target.checked) {
      addInvoiceToPrint(obj);
    }
    else
      removeInvoiceToPrint(valueSplitted[0]);
    setShowNotification(true);
  }
  //#endregion

  return (
    <div className="table-responsive-lg">
      <table className="table">
        <thead>
          <tr >
            <th scope="col"></th>
            <th scope="col">Numero</th>
            <th scope="col">Data</th>
            <th scope="col">Cod.Cliente</th>
            {/* <th scope="col">Nome Cliente</th> */}
            <th scope="col">Pagamento</th>
            <th scope="col">Protocollo</th>
            <th scope="col">Flusso</th>
            {/* <th scope="col">PDF</th> */}
            <th scope="col">Note</th>
          </tr>
        </thead>
        {
          data.items === undefined ?
            <tbody className='text-center'>
              <tr>
                <td colSpan="12">Nessun risultato</td>
              </tr>
            </tbody>
            :
            <tbody>
              {
                data.items.map((el, index) => {
                  const invoiceDate = new Date(el.invoiceDate);
                  // const expirationDate = el.expirationDate === "1753-01-01T00:00:00" ? null : new Date(el.expirationDate);
                  return (
                    <tr key={index} className="form-control-sm">
                      <th scope="row"><input className="form-check-input" type="checkbox" value={el.number + "#" + el.protocol} name="flexCheckDefault" onClick={handleCheckbox} defaultChecked={el.selected} /></th>
                      <td>{el.number}</td>
                      <td>{invoiceDate.toLocaleDateString("it-IT")}</td>
                      <td>{el.customerCode}</td>
                      {/* <td>{el.customerName}</td> */}
                      <td>{el.paymentMehtod}</td>
                      <td>{el.protocol}</td>
                      <td>{el.flow}</td>
                      {/* <td>{el.pdf ? "SI" : "NO"}</td> */}
                      <td>{el.note}</td>
                    </tr>
                  )
                })
              }
            </tbody>
        }
      </table>
    </div>
  )
}

export default InvoiceAccountingTable