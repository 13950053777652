import React, { useState } from 'react'
import FlowFilters from './Filters'
import FlowTable from './Table';
import Pagination from '../../Shared/Pagination';
import { usePagination } from '../../../CustomHook/usePagination';
import Loading from '../../Shared/Loading';

const FlowList = () => {
  const [flowData, setFlowData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingFlows, setIsLoadingFlows] = useState(true);

  const getFlowData = (data) => {
    setFlowData(data);
  }

  //#region Pagination
  function goToNextPage(event) {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
  }

  function goToPreviousPage(event) {
    event.preventDefault();
    setCurrentPage(currentPage - 1);
  }

  function changePage(event) {
    event.preventDefault();
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  const paginationRange = usePagination({
    currentPage: currentPage,
    totalCount: flowData.count === undefined ? 0 : flowData.count,
    siblingCount: process.env.REACT_APP_PAGINATION_SIBLINGCOUNT,
    pageSize: process.env.REACT_APP_PAGINATION_PAGESIZE
  });
  //#endregion

  return (
    <div className='container'>
      <FlowFilters getFlowData={getFlowData} currentPage={currentPage} setIsLoadingFlows={setIsLoadingFlows} />
      <br />
      {
        isLoadingFlows ?
          <Loading />
          :
          <>
            <FlowTable data={flowData} />
            <br />
            <Pagination paginationRange={paginationRange} currentPage={currentPage} goToPreviousPage={goToPreviousPage}
              changePage={changePage} data={flowData} goToNextPage={goToNextPage} />
          </>
      }
    </div>
  )
}

export default FlowList