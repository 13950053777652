/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { instanceDownload } from "./app";
import { useSelector } from 'react-redux';

const useDownloadFlow = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [flowName, setFlowName] = useState(undefined);
    const [downloadStream, setDownloadStream] = useState(null);
    const [fileNameDownload, setFileNameDownload] = useState(null);
    const { user } = useSelector(store => store);

    //#region download flow
    const getFlowName = (flowname) => {
        setFlowName(flowname);
    }
    const getDownloadStream = async () => {
        setDownloadStream(null);
        try {
            setIsLoading(true);
            if (!user.loggedIn)
                throw new Error("User not authenticated")
            if (flowName === undefined)
                return null;
            var response = await instanceDownload.get(`/flow/download?flowName=${flowName}`);
            const contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    setFileNameDownload(matches[1].replace(/['"]/g, ""));
                }
            }

            setDownloadStream(response.data);
        } catch (error) {
            console.log("error; " + error);
            setDownloadStream(null);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        getDownloadStream();
    }, [flowName]);
    //#endregion

    return { isLoading, getFlowName, fileNameDownload, downloadStream }
}

export default useDownloadFlow;
