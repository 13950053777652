import { onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../Firebase/firebase';
import { setActiveUser, setUserLogOutState, setLoginError, setCheckingUser, setPasswordResetSuccess, setPasswordResetFail } from '../reducers/userReducer';

export const checkUserState = () => async (dispatch) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            dispatch(setActiveUser(user.email));
        }
        else {
            dispatch(setUserLogOutState());
        }
    });
}

export const login = (email, password) => async (dispatch) => {
    dispatch(setCheckingUser());
    signInWithEmailAndPassword(auth, email, password)
        .then((data) => {
            dispatch(setActiveUser(data.user.email));
        })
        .catch((error) => {
            dispatch(setLoginError(error.code));
        })
}

export const logout = () => async () => {
    signOut(auth);
}

export const resetPassword = (email, logoutUser) => async (dispatch) => {
    if (logoutUser)
        signOut(auth);

    await sendPasswordResetEmail(auth, email)
        .then(
            () => { dispatch(setPasswordResetSuccess({ errorMessage: "", logoutUser: logoutUser })) }
        )
        .catch(
            error => {
                dispatch(setPasswordResetFail({ errorMessage: error.message, logoutUser: logoutUser }));
            }
        );

}