import React, { useEffect } from 'react'
import useFetchClimaticZone from '../../../CustomHook/FetchData/useFetchClimaticZone'
import useClimaticZone from '../../../CustomHook/useClimaticZone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from '../../Shared/Loading'
import Alert from '../../Shared/Alert'

const ClimaticZoneReclaim = () => {
    const { getReclaim, reclaim, isLoading } = useFetchClimaticZone();
    const { downloadClimaticZoneToReclaim, isDownloading, fileNameDownload, downloadStream, reclaimClimaticZone, isReclaiming, reclaimError } = useClimaticZone();

    useEffect(() => {
        if (!isReclaiming)
            getReclaim();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReclaiming])

    useEffect(() => {
        if (!isDownloading && downloadStream !== null) {

            const link = document.createElement('a');
            link.setAttribute('download', fileNameDownload);
            link.href = URL.createObjectURL(new Blob([downloadStream], { type: "application/octet-stream" }));
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDownloading])

    const reclaimZone = (e) => {
        e.preventDefault();
        reclaimClimaticZone();
    }

    return (
        <form onSubmit={reclaimZone}>
            <h3>Bonifica zone climatiche</h3>
            <br />
            {
                isLoading || isDownloading || isReclaiming ?
                    <Loading />
                    :
                    <div className='row'>
                        <div className='col-md-2'>
                            <button type="submit" className="btn btn-lg btn-primary" disabled={reclaim === undefined} >Bonifica</button>
                        </div>
                        <div className='col-md-3 d-flex align-items-center'>
                            {
                                reclaim === undefined ?
                                    <>Nessuna zona climatica da bonificare</>
                                    :
                                    <>Zone climatiche da bonificare: {reclaim.length}</>
                            }
                        </div>
                        {
                            reclaim === undefined ?
                                <></>
                                :
                                <div className='col-md-1'>
                                    <FontAwesomeIcon icon="fa-light fa-download" size='xl' onClick={() => {
                                        downloadClimaticZoneToReclaim();
                                    }} style={{ cursor: "pointer" }} />
                                </div>
                        }
                    </div>
            }
            <br />
            <Alert message={reclaimError} type="danger" show={reclaimError !== ""} />
        </form>
    )
}

export default ClimaticZoneReclaim