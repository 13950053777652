/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { instance, instanceDownload } from './app';
import { useSelector } from 'react-redux';

const useClimaticZone = () => {
    const { user } = useSelector(store => store);
    const [bodyUpdate, setBodyUpdate] = useState(undefined);
    const [updateError, setUpdateError] = useState("");
    const [isUpdating, setIsUpdating] = useState(false);
    const [downloadStream, setDownloadStream] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [fileNameDownload, setFileNameDownload] = useState(null);
    const [isReclaiming, setIsReclaiming] = useState(false);
    const [reclaimError, setReclaimError] = useState("");

    //#region update missing climatic zones
    const updateClimaticZoneMissing = async () => {
        try {
            if (!user.loggedIn || bodyUpdate === undefined)
                return null;
            setIsUpdating(true);
            var response = await instance.patch(`/climaticzone/update`, bodyUpdate);
            if (response.status !== 200)
                setUpdateError("The status returned is " + response.status + "\n" +
                    "Here below the response:\n " + response)
            setIsUpdating(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region download climatic zone to reclaim
    const downloadClimaticZoneToReclaim = async () => {
        setDownloadStream(null);
        try {
            setIsDownloading(true);
            if (!user.loggedIn)
                console.error("User not logged");
            var response = await instanceDownload.get(`/climaticzone/zones-to-reclaim/download`);
            const contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    setFileNameDownload(matches[1].replace(/['"]/g, ""));
                }
            }

            setDownloadStream(response.data);
        } catch (error) {
            console.error("error; " + error);
            setDownloadStream(null);
        }
        setIsDownloading(false);
    }
    //#endregion

    //#region reclaim climatic zone

    const reclaimClimaticZone = async () => {
        try {
            if (!user.loggedIn)
                return null;
            setIsReclaiming(true);
            var response = await instance.patch(`/climaticzone/reclaim`, null);
            if (response.status !== 200)
                setReclaimError("The status returned is " + response.status + "\n" +
                    "Here below the response:\n " + response)
            setIsReclaiming(false);
        } catch (error) {
            console.error(error);
        }
    }

    //#endregion

    useEffect(() => {
        updateClimaticZoneMissing()
    }, [bodyUpdate])

    return {
        setBodyUpdate,
        downloadClimaticZoneToReclaim,
        reclaimClimaticZone,
        updateError,
        downloadStream,
        fileNameDownload,
        isDownloading,
        isUpdating,
        isReclaiming,
        reclaimError
    }
}

export default useClimaticZone;
