import { useState, useEffect } from "react";
import { instance } from "../app"
import { useSelector } from 'react-redux';

const useFetchFunction = () => {
    const { user } = useSelector(store => store);
    const [isLoadingFunction, setIsLoadingFunction] = useState(true);
    const [isLoadingFunctionRoles, setIsLoadingFunctionRoles] = useState(true);
    const [isLoadingFunctionRolesUnassigned, setIsLoadingFunctionRolesUnassigned] = useState(true);
    const [isLoadingFunctionDetails, setIsLoadingFunctionDetails] = useState(true);
    const [functions, setFunctions] = useState(undefined);
    const [functionRoles, setFunctionRoles] = useState(undefined);
    const [functionRolesUnassigned, setFunctionRolesUnassigned] = useState(undefined);
    const [functionDetails, setFunctionDetails] = useState(undefined);
    const [functionReq, setFunctionReq] = useState({ applicationId: "", functionName: "" })
    const [functionId, setFunctionId] = useState(undefined);

    //#region get functions
    const getFunctions = async () => {
        try {
            if (!user.loggedIn)
                return null;
            setIsLoadingFunction(true);
            var response = await instance.get(`/admin/functions?applicationId=${functionReq.applicationId}&functionName=${functionReq.functionName}`);
            if (response.status === 204)
                setFunctions(undefined);
            else
                setFunctions(response.data);
            setIsLoadingFunction(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get function roles
    const getFunctionRoles = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (functionId === undefined)
                return;
            setIsLoadingFunctionRoles(true);
            var response = await instance.get(`/admin/function/${functionId}/roles`);
            if (response.status === 204)
                setFunctionRoles(undefined);
            else
                setFunctionRoles(response.data);
            setIsLoadingFunctionRoles(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get function details
    const getFunctionDetails = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (functionId === undefined)
                return;
            setIsLoadingFunctionDetails(true);
            var response = await instance.get(`/admin/function/${functionId}`);
            setFunctionDetails(response.data);
            setIsLoadingFunctionDetails(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get function roles unassigned
    const getFunctionRolesUnassigned = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (functionId === undefined)
                return;
            setIsLoadingFunctionRolesUnassigned(true);
            var response = await instance.get(`/admin/function/${functionId}/roles-unassigned`);
            if (response.status === 204)
                setFunctionRolesUnassigned(undefined);
            else
                setFunctionRolesUnassigned(response.data);
            setIsLoadingFunctionRolesUnassigned(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion


    useEffect(() => {
        getFunctions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functionReq])

    useEffect(() => {
        getFunctionRoles();
        getFunctionDetails();
        getFunctionRolesUnassigned();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functionId])



    return {
        getFunctions,
        getFunctionDetails,
        setFunctionReq,
        setFunctionId,
        getFunctionRoles,
        functions,
        functionRoles,
        functionDetails,
        functionRolesUnassigned,
        isLoadingFunction,
        isLoadingFunctionRoles,
        isLoadingFunctionDetails,
        isLoadingFunctionRolesUnassigned,
    }
}

export default useFetchFunction