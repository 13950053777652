import React, { useState } from 'react'
import useFile from '../../CustomHook/useFile';
import Alert from '../Shared/Alert';
import Loading from '../Shared/Loading';

const RaiFee = () => {
    const { setBodyRaiFee, isLoadingRaiFee, isErrorRaiFee, errorMessageRaiFee } = useFile();
    const [fileName, setFileName] = useState();
    const [file, setFile] = useState();

    const changeHandler = (e) => {
        if (e.target.files[0] && e.target.files) {
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name)
        }
    }
    const handleSubmit = () => {
        if (file === undefined && fileName === undefined)
            return;
        const formData = new FormData();
        formData.append('formFile', file);
        setBodyRaiFee(formData);
        setFileName();
    }
    return (
        <div className='container'>
            {
                isLoadingRaiFee ?
                    <Loading />
                    :
                    <>
                        <h2>CLIENTI CANONE RAI</h2>
                        <div className="mb-3">
                            <label htmlFor="raiFeeFile" className="form-label">File input</label>
                            <input className="form-control" type="file" id="raiFeeFile" accept='.xlsx' onChange={changeHandler} />
                        </div>
                        <div className="mb-3">
                            <button className="btn btn-lg btn-primary" disabled={fileName === undefined} onClick={() => { handleSubmit() }}>Carica File</button>
                        </div>
                        <Alert type="danger" message={errorMessageRaiFee} show={isErrorRaiFee} />
                        <Alert type="success" message="File caricato correttamente" show={isErrorRaiFee === false} />
                    </>
            }

        </div>
    )
}

export default RaiFee