import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { instance } from "../app";

const useFetchAdmin = () => {
    const { user } = useSelector(store => store);
    const [applications, setApplications] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [applicationId, setApplicationId] = useState(undefined);
    const [applicationDetail, setApplicationDetail] = useState(undefined);

    //#region get applications
    const getApplications = async () => {
        try {
            if (!user.loggedIn)
                return null;
            setIsLoading(true);
            var response = await instance.get(`/admin/application`);
            setApplications(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get application details
    const getApplicationDetail = async () => {
        try {
            setIsLoading(true);
            if (!user.loggedIn || applicationId === undefined)
                return;
            var response = await instance.get(`/admin/application/${applicationId}`);
            if (response.status === 204)
                console.error(`No application found with id ${applicationId}`);
            setApplicationDetail(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    useEffect(() => {
        getApplicationDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId])
    return {
        getApplications,
        setApplicationId,
        applications,
        applicationDetail,
        isLoading
    }
}

export default useFetchAdmin