/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { instance } from "../app"
import { useSelector } from 'react-redux';

const useFetchInvoice = () => {
    const { user } = useSelector(store => store);
    const [invoicePaginatedReq, setInvoicePaginatedReq] = useState(undefined);
    const [invoicePaginatedResp, setInvoicePaginatedResp] = useState([]);
    const [invoicesToPrintReq, setInvoicesToPrintReq] = useState(undefined);
    const [invoicesToPrintResp, setInvoicesToPrintResp] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInvoicesToPrint, setIsLoadingInvoicesToPrint] = useState(undefined);
    const [invoicePaginatedError, setInvoicePaginatedError] = useState(undefined);
    const pageSize = process.env.REACT_APP_PAGINATION_PAGESIZE;

    const getPageNumber = (value) => {
        setCurrentPage(value);
    }

    //#region invoice paginated
    const getInvoicePaginatedReq = (object) => {
        setCurrentPage(1);
        setInvoicePaginatedReq(object);
    }

    const getInvoicePaginated =
        async () => {
            try {
                if (invoicePaginatedReq === undefined) {
                    setInvoicePaginatedError("invoicePaginatedReq is undefined");
                    return null;
                }
                if (user.email === null) {
                    setInvoicePaginatedError("user is null");
                    return null;
                }
                setInvoicePaginatedError(undefined);
                setIsLoading(true);
                var response = await instance.post(`/invoice/billing/invoices-paginated?pageSize=${pageSize}&pageNumber=${currentPage}&sort=NumeroFattura`, invoicePaginatedReq);
                setInvoicePaginatedResp(response.data);
            } catch (error) {
                console.log("error: " + error);
                setInvoicePaginatedError(error.response.data.error.message);
            }
            setIsLoading(false);
        }

    useEffect(() => {
        getInvoicePaginated();
    }, [invoicePaginatedReq, currentPage]);
    //#endregion

    //#region get invoice to print
    const getInvoicesToPrintReq = (object) => {
        setInvoicesToPrintReq(object);
    }

    const getInvoicesToPrint =
        async () => {
            try {
                if (invoicesToPrintReq === undefined || !user.loggedIn) {
                    return null;
                }
                setIsLoadingInvoicesToPrint(true);
                var response = await instance.post(`/invoice/billing/invoices-list`, invoicesToPrintReq);
                setInvoicesToPrintResp(response.data);
                setIsLoadingInvoicesToPrint(false);
            } catch (error) {
                console.log("error: " + error);
            }
        }

    useEffect(() => {
        getInvoicesToPrint();
    }, [invoicesToPrintReq]);
    //#endregion

    return { invoicePaginatedResp, getInvoicePaginatedReq, getPageNumber, isLoading, isLoadingInvoicesToPrint, getInvoicesToPrintReq, invoicesToPrintResp, invoicePaginatedError }
}

export default useFetchInvoice;
