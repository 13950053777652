import { Store } from 'react-notifications-component';  //https://www.npmjs.com/package/react-notifications-component

/**
 * looks for an specific item of the list.
 *
 * @param {string} notificationId Id of the notification
 * @param {string} message Message of the notification
 */
export function ShowInfoNotification(notificationId, message) {
    Store.addNotification({
        id: notificationId,
        message: message,
        type: "info",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { click: false, touch: false }
    })
}

/**
 * looks for an specific item of the list.
 *
 * @param {string} notificationId Id of the notification
 * @param {string} message Message of the notification
 */
export function ShowErrorNotification(notificationId, message) {
    Store.addNotification({
        id: notificationId,
        message: message,
        type: "danger",
        insert: "top",
        container: "bottom-center",
        animationIn: ["animate__animated", "zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { click: true, touch: true, showIcon: true }
    })
}

/**
 * looks for an specific item of the list.
 *
 * @param {string} notificationId Id of the notification
 * @param {string} message Message of the notification
 */
export function ShowCounterNotification(notificationId, message) {
    Store.addNotification({
        id: notificationId,
        message: message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { click: true, touch: true, duration: 0, showIcon: true }
    })
}

/**
 * looks for an specific item of the list.
 *
 * @param {string} message Message of the notification
 * @param {number} duration Time in seconds after notification gets dismissed. 0 will act as infinite duration. Defaults to 0
 */
export function ShowSuccessNotification(message, duration) {
    duration = duration * 1000;
    Store.addNotification({
        message: message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { click: true, touch: true, duration: duration, showIcon: true }
    })
}
/**
 * @param {string} message Message of the notification
 * @param {number} duration Time in seconds after notification gets dismissed. 0 will act as infinite duration. Defaults to 0
 */
export function ShowErrorTimerNotification(message, duration) {
    duration = duration * 1000;
    Store.addNotification({
        message: message,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { click: true, touch: true, duration: duration, showIcon: true }
    })
}

/**
 * looks for an specific item of the list.
 *
 * @param {string} notificationId Id of the notification to remove
 */
export function RemoveNotification(notificationId) {
    Store.removeNotification(notificationId);
}
