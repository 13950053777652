import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons"
import { useSelector } from 'react-redux';
import Loading from './Shared/Loading';

//font awesome
library.add(fas, fal);

const HomePage = () => {
    const { applications } = useSelector(store => store);

    return (
        <>
            <div className='container'>
                <div className="row mb-3 text-start">
                    {
                        applications.loading
                            ?
                            <Loading />
                            :
                            applications.data.map((el, index) => {
                                return (
                                    <div className="col-12 col-lg-4" key={index}>
                                        <Link className="nav-link" key={el.alias} to={"/" + el.alias.toLowerCase()} >
                                            <div className="card mb-4">
                                                <div className="card-header card-header-image text-center">
                                                    <FontAwesomeIcon icon={el.icon} size='10x' />
                                                </div>
                                                <div className="card-body">
                                                    <h4 className="title-card-body my-0 fw-normal text-center">{el.applicationName}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                </div>
            </div>
        </>
    )
}

export default HomePage;