import { useState } from 'react'

const useInvoiceToPrint = () => {

    const [invoiceToPrint, setInvoiceToPrint] = useState([]);

    const addInvoiceToPrint = (obj) => {
        setInvoiceToPrint([
            ...invoiceToPrint, {
                invoiceNumber: obj.invoiceNumber,
                protocol: obj.protocol
            }
        ]
        );
    }

    const removeInvoiceToPrint = (invoiceNumber) => {
        const arr = invoiceToPrint.filter(item => item.invoiceNumber !== invoiceNumber);
        setInvoiceToPrint(arr);
    }

    const addAllInvoiceToPrint = (value) => {
        if (value === null)
            return;
        if (value.length <= 0)
            return;
        setInvoiceToPrint(value);
    }
    const removeAllInvoiceToPrint = () => {
        setInvoiceToPrint([]);
    }

    return {
        invoiceToPrint,
        addInvoiceToPrint,
        removeInvoiceToPrint,
        addAllInvoiceToPrint,
        removeAllInvoiceToPrint
    }
}

export default useInvoiceToPrint;