import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import useFetchUser from '../../../CustomHook/FetchData/useFetchUser';
import useUser from '../../../CustomHook/useUser';
import Loading from '../../Shared/Loading';

const NewUserModal = ({ setUserReq }) => {
    const { user } = useSelector(store => store);

    const { getRoles, roles, isLoadingRoles } = useFetchUser();
    const { setAddUserReq, isAddingUser } = useUser();

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");

    useEffect(() => {
        if (user.loggedIn)
            getRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking])

    const handleSubmit = (e) => {
        e.preventDefault();
        setAddUserReq({
            name: name,
            surname: surname,
            email: email,
            roleId: role
        })
    }

    useEffect(() => {
        if (!isAddingUser) {
            document.getElementById('closeModalButton').click();
            setUserReq({ name: null, email: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingUser])
    return (
        <form onSubmit={handleSubmit}>
            <div className="modal fade" id="newUserModal" tabIndex="-1" aria-labelledby="newUserModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="newUserModalLabel">Nuovo utente
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='closeModalButton'></button>
                        </div>

                        <div className="modal-body">
                            {
                                isLoadingRoles ?
                                    <Loading />
                                    :
                                    <>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" required onChange={(e) => setName(e.target.value)} />
                                            <label >Nome</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" required onChange={(e) => setSurname(e.target.value)} />
                                            <label >Cognome</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" required onChange={(e) => setEmail(e.target.value)} />
                                            <label >Email</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <select className="form-select" id="floatingSelect" required onChange={(e) => setRole(e.target.value)}>
                                                <option value="">Seleziona ruolo</option>
                                                {
                                                    roles.map((el, index) => {
                                                        return <option key={index} value={el.id}>{el.name}</option>
                                                    })
                                                }
                                            </select>
                                            <label htmlFor="floatingSelect">Ruolo</label>
                                        </div>
                                    </>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Salva</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default NewUserModal