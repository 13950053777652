import React, { useState } from 'react'
import Loading from '../Shared/Loading';
import useFile from '../../CustomHook/useFile';
import Alert from '../Shared/Alert';

const BonusEe = () => {
    const { setBodyBonusEe, isLoadingBonusEe, isErrorBonusEe, errorMessageBonusEe } = useFile();
    const [fileName, setFileName] = useState();
    const [file, setFile] = useState();

    const changeHandler = (e) => {
        if (e.target.files[0] && e.target.files) {
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name)
        }
    }
    const handleSubmit = () => {
        if (file === undefined && fileName === undefined)
            return;
        const formData = new FormData();
        formData.append('formFile', file);
        setBodyBonusEe(formData);
        setFileName();
    }

    return (
        <div className='container'>
            {
                isLoadingBonusEe ?
                    <Loading />
                    :
                    <>
                        <h2>CLIENTI BONUS SOCIALE</h2>
                        <div className="mb-3">
                            <label htmlFor="redZoneFile" className="form-label">File input</label>
                            <input className="form-control" type="file" id="redZoneFile" accept='.xlsx' onChange={changeHandler} />
                        </div>
                        <div className="mb-3">
                            <button className="btn btn-lg btn-primary" disabled={fileName === undefined} onClick={() => { handleSubmit() }}>Carica File</button>
                        </div>
                        <Alert type="danger" message={errorMessageBonusEe} show={isErrorBonusEe} />
                        <Alert type="success" message="File caricato correttamente" show={isErrorBonusEe === false} />
                    </>
            }

        </div>
    )
}

export default BonusEe