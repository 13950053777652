import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const Filters = ({ setUserReq }) => {
    const { user } = useSelector(store => store);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        setUserReq({
            name: name,
            email: email
        });
    }

    useEffect(() => {
        if (user.loggedIn)
            setUserReq({
                name: name,
                email: email
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking])

    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-md-5'>
                    <label className="form-label">Nome</label>
                    <input className="form-control" type="text" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className='col-md-5'>
                    <label className="form-label">Email</label>
                    <input className="form-control" type="text" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='col-md-2 d-flex' >
                    <button type='submit' className="btn btn-primary" >Cerca</button>
                </div>
            </div >
        </form>
    )
}

export default Filters