/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { instance } from "../app"
import { useSelector } from 'react-redux';

const useFetchUser = () => {
    const { user } = useSelector(store => store);
    const [functions, setFunctions] = useState(undefined);
    const [userReq, setUserReq] = useState({ name: undefined, email: undefined });
    const [userResp, setUserResp] = useState(undefined);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [userId, setUserId] = useState(undefined);
    const [isLoadingUserDetail, setIsLoadingUserDetail] = useState(true);
    const [userDetailResp, setDetailUserResp] = useState(undefined);
    const [userUnassignedRoles, setUserUnassignedRoles] = useState(undefined);
    const [isLoadingUnassignedRoles, setIsLoadingUnassignedRoles] = useState(true);
    const [isLoadingUserRoles, setIsLoadingUserRoles] = useState(true);
    const [userRoles, setUserRoles] = useState(undefined);
    const [roles, setRoles] = useState(undefined);
    const [isLoadingRoles, setIsLoadingRoles] = useState(true);
    const [userExists, setUserExists] = useState(false);
    const [isLoadingCheckUser, setIsLoadingCheckUser] = useState(true);

    //set to true!!!
    const [isLoading, setIsLoading] = useState(true);

    //#region check if user exists in database
    const checkUser = async (email) => {
        try {
            setIsLoadingCheckUser(true);
            var response = await instance.get(`/user/check-user?email=${email}`);
            if (response.status === 204)
                setUserExists(false);
            else
                setUserExists(true);
            setIsLoadingCheckUser(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get functions for logged user
    const getFunctions = async (applicationAlias) => {
        try {
            if (!user.loggedIn)
                return null;
            setIsLoading(true);
            var response = await instance.get(`/user/functions?email=${user.email}&applicationAlias=${applicationAlias}`);
            setFunctions(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get users
    const getUsers = async () => {
        try {
            if (!user.loggedIn)
                return null;
            setIsLoadingUser(true);
            var response;

            if (userId !== undefined)
                response = await instance.get(`/user/${userId}`);
            else if ((userReq.email === null && userReq.name === null) || (userReq.email === undefined && userReq.name === undefined))
                response = await instance.get(`/user`);
            else
                response = await instance.get(`/user?name=${userReq.name}&email=${userReq.email}`);

            if (response.status === 204)
                setUserResp(undefined);
            else
                setUserResp(response.data);
            setIsLoadingUser(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get user detail
    const getUser = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (userId === undefined)
                return;
            setIsLoadingUserDetail(true);
            var response = await instance.get(`/user/${userId}`);
            if (response.status === 204)
                setDetailUserResp(undefined);
            else
                setDetailUserResp(response.data);
            setIsLoadingUserDetail(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get users unassigned roles
    const getUserUnassignedRoles = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (userId === undefined)
                return;
            setIsLoadingUnassignedRoles(true);
            var response = await instance.get(`/user/${userId}/unassigned-roles`);

            if (response.status === 204)
                setUserUnassignedRoles(undefined);
            else
                setUserUnassignedRoles(response.data);
            setIsLoadingUnassignedRoles(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get users roles
    const getUserRoles = async () => {
        try {
            if (!user.loggedIn)
                return null;
            if (userId === undefined)
                return;
            setIsLoadingUserRoles(true);
            var response = await instance.get(`/user/${userId}/roles`);

            if (response.status === 204)
                setUserRoles(undefined);
            else
                setUserRoles(response.data);
            setIsLoadingUserRoles(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region get users roles
    const getRoles = async () => {
        try {
            if (!user.loggedIn)
                return null;
            setIsLoadingRoles(true);
            var response = await instance.get(`/user/roles`);

            if (response.status === 204)
                setRoles(undefined);
            else
                setRoles(response.data);
            setIsLoadingRoles(false);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    useEffect(() => {
        getUsers();
    }, [userReq])

    useEffect(() => {
        getUser();
        getUserUnassignedRoles();
        getUserRoles();
    }, [userId])

    return {
        getFunctions,
        setUserReq,
        setUserId,
        getUserRoles,
        getUserUnassignedRoles,
        getRoles,
        checkUser,
        functions,
        userResp,
        userDetailResp,
        userUnassignedRoles,
        userRoles,
        roles,
        userExists,
        isLoading,
        isLoadingUser,
        isLoadingUserDetail,
        isLoadingUnassignedRoles,
        isLoadingUserRoles,
        isLoadingRoles,
        isLoadingCheckUser
    }
}

export default useFetchUser;
