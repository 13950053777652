import { startLoading, stopLoading, saveData, catchError, cleanError } from "../reducers/breadcrumbsReducer"
import { instance } from "../../CustomHook/app";


export const getBreadcrumbs = (user) => async (dispatch) => {
    if (!user)
        return;
    dispatch(startLoading());
    dispatch(cleanError());
    try {
        var response = await instance.get(`/setup/breadcrumb-config`);
        if (response.status === 200)
            dispatch(saveData(response.data));
        else
            dispatch(catchError("The status returned is " + response.status + "\n" +
                "Here below the response:\n " + response))
    } catch (error) {
        console.log(error);
        dispatch(catchError(error.response.data.error.message));
    }
    dispatch(stopLoading());
}