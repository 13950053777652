import React from 'react'
import Loading from '../../../Shared/Loading';

const RolesDetail = ({ userRoles, isLoadingUserRoles, setRoleIdToDelete }) => {

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-10'>
                    <h3>Ruoli</h3>
                </div>
                <div className='col-md-2'>
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#assignRoleUserModal">Associa nuovo ruolo</button>
                </div>
            </div>
            <>
                {
                    isLoadingUserRoles ?
                        <Loading />
                        :
                        <div className="table-responsive-lg">
                            <table className="table">
                                <thead>
                                    <tr >
                                        <th scope="col">Nome</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                {
                                    userRoles === undefined ?
                                        <tbody className='text-center'>
                                            <tr>
                                                <td colSpan="12">Nessun risultato</td>
                                            </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                            {
                                                userRoles.map((el, index) => {
                                                    return (
                                                        <tr key={index} className="form-control-sm" valign="middle">
                                                            <td className='text-center'>{el.name}</td>
                                                            <td className='text-center'>
                                                                <button type='button' className='btn btn-primary' onClick={() => { setRoleIdToDelete(el.id) }}>Elimina</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                }
                            </table>
                        </div>
                }
            </>
            <br /><br />
        </div>

    )
}

export default RolesDetail