import React from 'react'

const TotalPages = ({ total }) => {
    return (
        <div className='col-md-2' style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <label>Totale: {total}</label>
        </div>
    )
}

export default TotalPages