import React, { useEffect } from 'react'
import { RemoveNotification } from '../../Shared/Notification';
import useDownloadFlow from '../../../CustomHook/useDownloadFlow'
import TableBilling from './TableBilling';
import TableAccounting from './TableAccounting';
import { IsAccounting } from '../../Shared/Common';

const FlowTable = ({ data }) => {
    const { isLoading, getFlowName, fileNameDownload, downloadStream } = useDownloadFlow();

    const notificationId = "flowDownloadNotification";
    const isAccounting = IsAccounting();
    useEffect(() => {
        if (!isLoading) {
            RemoveNotification(notificationId);
            if (downloadStream === null) {
                alert("Nessun flusso da scaricare");
                return;
            }
            const link = document.createElement('a');
            link.setAttribute('download', fileNameDownload);
            link.href = URL.createObjectURL(new Blob([downloadStream], { type: "application/octet-stream" }));
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, downloadStream])

    return (
        isAccounting ?
            <TableAccounting data={data} />
            :
            <TableBilling data={data} getFlowName={getFlowName} />
    )
}

export default FlowTable