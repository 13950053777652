import React, { useEffect, useState } from 'react'
import useFetchClimaticZone from '../../../CustomHook/FetchData/useFetchClimaticZone'
import useClimaticZone from '../../../CustomHook/useClimaticZone';
import Loading from '../../Shared/Loading';
import Alert from '../../Shared/Alert';

const ClimaticZoneMissing = () => {
    const { getMissing, missing, isLoading } = useFetchClimaticZone();
    const { setBodyUpdate, updateError, isUpdating } = useClimaticZone();

    const [dataToSend, setDataToSend] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (!isUpdating)
            getMissing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdating])

    //set dataToSend with climatic zone = null
    useEffect(() => {
        if (missing === undefined)
            return;
        const newObj = missing.map(obj => {
            return { ...obj, climaticZone: null }
        });
        setDataToSend(newObj);
    }, [missing])

    const updateObjectInArray = (cz, element) => {
        setDataToSend(current =>
            current.map(obj => {
                if (obj.province === element.province && obj.city === element.city)
                    return { ...obj, climaticZone: cz };
                else
                    return obj;
            })
        );
    };

    const sendData = (e) => {
        e.preventDefault();
        var message = "";
        dataToSend.map(obj => {
            if (obj.climaticZone === null || obj.climaticZone === "")
                message += "Impostare la zona climatica per la città di " + obj.city + "\n";
            return message;
        });
        setErrorMessage(message);
        if (message === "" && dataToSend !== undefined)
            setBodyUpdate(dataToSend);
    }

    return (
        <form onSubmit={sendData}>
            <div>
                <h3>Zone climatiche mancanti</h3>
            </div>
            {
                isLoading ?
                    <Loading />
                    :
                    <div className="table-responsive-lg">
                        <table className="table">
                            <thead>
                                <tr >
                                    <th scope="col">Città</th>
                                    <th scope="col">Provincia</th>
                                    <th scope="col">Zona climatica</th>
                                </tr>
                            </thead>
                            {
                                missing === undefined ?
                                    <tbody className='text-center'>
                                        <tr>
                                            <td colSpan="12">Nessun risultato</td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        {
                                            missing.map((el, index) => {
                                                return (
                                                    <tr key={index} className="form-control-sm">

                                                        <td>{el.city}</td>
                                                        <td>{el.province}</td>
                                                        <td>
                                                            <select className="form-select" aria-label="Default select example" onChange={(e) => { updateObjectInArray(e.target.value, el) }} >
                                                                <option value="">Seleziona zona climatica...</option>
                                                                {
                                                                    el.climaticZone.map((elm, index) => {
                                                                        return <option key={index} value={elm}>{elm}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                            }
                        </table>
                        <Alert message={errorMessage} type="danger" show={errorMessage !== ""} />
                        <Alert message={updateError} type="danger" show={updateError !== ""} />
                        <button type="submit" className="btn btn-lg btn-primary" disabled={missing === undefined} >Conferma</button>
                    </div>
            }
        </form>
    )
}

export default ClimaticZoneMissing