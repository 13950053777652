import React, { useEffect, useState } from 'react'
import Loading from '../Shared/Loading';
import useFetchNav from '../../CustomHook/FetchData/useFetchNav';

const ApplibraCall = () => {
    const { setInvoiceNumber, isLoadingNav, navResponse, errorMessageNav } = useFetchNav();
    const [textAreaValue, setTextAreaValue] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setInvoiceNumber(document.getElementById("invoiceNumber").value === "" ? undefined : document.getElementById("invoiceNumber").value.replace("/", "_"))
    }

    const handleCopyButton = () => {
        navigator.clipboard.writeText(document.getElementById("textAreaOutput").value);
    }
    useEffect(() => {
        if (errorMessageNav !== undefined)
            setTextAreaValue(errorMessageNav);
        if (navResponse !== undefined)
            setTextAreaValue(
                "invoiceNo	-->	" + navResponse.invoiceNumber + "\n" +
                "nrCliente	-->	" + navResponse.customerCode + "\n" +
                "residuoFattura	-->	" + navResponse.invoiceResidual + "\n" +
                "nrReferenti	-->	" + navResponse.referentNumber + "\n" +
                "sintetico	-->	" + navResponse.synthetic + "\n" +
                "dettaglio	-->	" + navResponse.detail + "\n" +
                "entrambi	-->	" + navResponse.both + "\n" +
                "residuoCliente	-->	" + navResponse.customerResidual + "\n" +
                "nomeReferente	-->	" + navResponse.referentName + "\n" +
                "telefonoReferente	-->	" + navResponse.referentPhone + "\n" +
                "scaduto	-->	" + navResponse.overdue + "\n" +
                "soloPdf	-->	" + navResponse.pdfOnly + "\n" +
                "dataInvioMail	-->	" + navResponse.sendMailDate + "\n" +
                "xMLSiNo	-->	" + navResponse.electronicInvoice + "\n"
            )
    }, [navResponse, errorMessageNav])

    return (
        <div className='container'>
            <h2>TRACCIATO CHIAMATA APPLIBRA</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input id="invoiceNumber" className="form-control" type="text" placeholder="Numero fattura" aria-label="default input example" required />
                </div>
                <div className="mb-3">
                    <button className="btn btn-lg btn-primary" >Recupera tracciato</button>
                    <button className="btn btn-lg btn-primary float-end" onClick={() => { handleCopyButton() }}>Copia</button>

                </div>
                {
                    isLoadingNav ?
                        <Loading />
                        :
                        <>
                            <textarea className="form-control" id="textAreaOutput" rows="20" disabled value={textAreaValue} />
                        </>
                }
            </form>
        </div>
    )
}

export default ApplibraCall