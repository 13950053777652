import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { checkUserState } from '../redux/Functions/userReduxFunctions';
import { getBreadcrumbs } from '../redux/Functions/breadcrumbsReduxFunctions'
import { getApplications } from '../redux/Functions/applicationsReduxFunctions'

const PrivateRoute = ({ children }) => {
    const { user } = useSelector(store => store);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkUserState());
        if (user.loggedIn) {
            dispatch(getApplications(user));
            dispatch(getBreadcrumbs(user));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking]);
    if (!user.checking)
        return (
            user.loggedIn ? children : <Navigate to="/" />
        )
}

export default PrivateRoute