import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import useFunction from '../../../CustomHook/useFunction';

const RolesModal = ({ functionRolesUnassigned, functionId, setReloadFunctionRoles }) => {
    const { user } = useSelector(store => store);
    const { isAssignRoleToFunction, setAssignRoleReq } = useFunction();

    const assignRole = (roleId) => {
        if (!user.loggedIn)
            return;
        setAssignRoleReq({ functionId: functionId, roleId: roleId });
    }

    useEffect(() => {
        if (!isAssignRoleToFunction)
            document.getElementById('closeModalButton').click();
        setReloadFunctionRoles(!isAssignRoleToFunction)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAssignRoleToFunction])

    return (
        <div className="modal fade" id="assignRoleModal" tabIndex="-1" aria-labelledby="assignRoleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="assignRoleModalLabel">Dettaglio
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='closeModalButton'></button>
                    </div>
                    <div className="table-responsive-lg">
                        <table className="table">
                            <thead>
                                <tr >
                                    <th scope="col">Nome</th>
                                    <th scope="col">Descrizione</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            {
                                functionRolesUnassigned === undefined ?
                                    <tbody className='text-center'>
                                        <tr>
                                            <td colSpan="12">Nessun risultato</td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        {
                                            functionRolesUnassigned.map((el, index) => {
                                                return (
                                                    <tr key={index} className="form-control-sm" valign="middle">
                                                        <td>{el.name}</td>
                                                        <td>{el.description}</td>
                                                        <td>
                                                            <button type='button' className='btn btn-primary' onClick={() => { assignRole(el.id) }}>Associa Ruolo</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                            }
                        </table>
                    </div>

                    <div className="modal-footer">
                        <button type="button" id="closeModalButton" className="btn btn-primary" data-bs-dismiss="modal">Chiudi</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RolesModal