import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import useFetchFunction from '../../../../CustomHook/FetchData/useFetchFunction';
import useFetchAdmin from '../../../../CustomHook/FetchData/useFetchAdmin';
import Loading from '../../../Shared/Loading';
import useFunction from '../../../../CustomHook/useFunction';

const FunctionDetail = ({ functionId }) => {
    const { user } = useSelector(store => store);
    const { setFunctionId, getFunctionDetails, functionDetails, isLoadingFunctionDetails } = useFetchFunction();
    const { getApplications, applications, isLoading } = useFetchAdmin();
    const { setUpdateFunctionReq, isUpdateFunction } = useFunction();

    const [alias, setAlias] = useState("");
    const [application, setApplication] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [position, setPosition] = useState("");
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (user.loggedIn) {
            setFunctionId(functionId);
            getApplications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functionId, user.checking])

    useEffect(() => {
        if (!isLoadingFunctionDetails && !isLoading) {
            setAlias(functionDetails.alias);
            setApplication(functionDetails.applicationId);
            setName(functionDetails.name);
            setDescription(functionDetails.description);
            setPosition(functionDetails.position);
            setHide(functionDetails.hide);
        }
    }, [functionDetails, isLoadingFunctionDetails, isLoading])

    useEffect(() => {
        if (!isUpdateFunction)
            getFunctionDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateFunction])

    const saveDetails = () => {
        var obj = {
            id: functionId,
            applicationId: application,
            alias: alias,
            name: name,
            description: description,
            hide: hide,
            position: position
        }
        setUpdateFunctionReq(obj);
    }

    return (
        <>
            {
                isLoading || isLoadingFunctionDetails ?
                    <Loading />
                    :
                    <div className='container'>
                        <h3>Dettagli</h3>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" value={alias} disabled={true} onChange={(e) => setAlias(e.target.value)} required />
                            <label >Alias</label>
                        </div>
                        <div className="form-floating">
                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" value={application} onChange={(e) => setApplication(e.target.value)}>
                                {
                                    applications.map((el, index) => {
                                        return <option key={index} value={el.id}>{el.name}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="floatingSelect">Applicativo</label>
                        </div>
                        <br />
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                            <label >Nome</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} required />
                            <label >Descrizione</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" value={position} onChange={(e) => setPosition(e.target.value)} required />
                            <label >Posizione</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={hide} onChange={(e) => setHide(e.target.checked)} />
                            <label className="form-check-label" >
                                Nascondi
                            </label>
                        </div>
                        <br />
                        <button type="button" className="btn btn-primary float-end" onClick={() => saveDetails()}>Salva</button>
                        <br />
                        <br />
                        <br />
                    </div>
            }
        </>
    )
}

export default FunctionDetail