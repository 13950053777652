import React, { useEffect, useState } from 'react'
import Table from './Table'
import Detail from './Detail'
import useFetchAdmin from '../../../CustomHook/FetchData/useFetchAdmin'
import Alert from '../../Shared/Alert'

const Home = () => {
  const { setApplicationId, applicationDetail } = useFetchAdmin();
  const [isSavingDetails, setIsSavingDetails] = useState(undefined);
  const [error, setError] = useState("");
  const [applicationObj, setApplicationObj] = useState(undefined);
  const [insertNew, setInsertNew] = useState(false);

  useEffect(() => {
    setApplicationObj(applicationDetail);
    setInsertNew(false);
  }, [applicationDetail])

  const newApplication = () => {
    var applicationObj = {
      id: null,
      alias: "",
      name: "",
      icon: "",
      homepageHide: false,
      menuHide: false,
      position: 0
  }
    setApplicationObj(applicationObj);
    setInsertNew(true);
  }

  return (
    <div className='container'>
      {
        <Alert type="danger" message={error} show={error !== ""} />
      }
      <Table setApplicationId={setApplicationId} isSaving={isSavingDetails} />
      <Detail applicationDetail={applicationObj} insertNew={insertNew} setIsSaving={setIsSavingDetails} setError={setError} />
      <button type="button" className="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#applicationDetailModal" onClick={newApplication}>Aggiungi</button>
    </div>
  )
}

export default Home