import React, { useState, useEffect } from 'react'
import useAdmin from '../../../CustomHook/useAdmin';

const Detail = ({ applicationDetail, insertNew, setIsSaving, setError }) => {

    const [alias, setAlias] = useState("");
    const [name, setName] = useState("");
    const [icon, setIcon] = useState("");
    const [position, setPosition] = useState("");
    const [homepageHide, setHomepageHide] = useState("");
    const [menuHide, setMenuHide] = useState("");

    const { setApplication, setNewApplication, isSaving, error } = useAdmin();

    useEffect(() => {
        if (!isSaving) {
            document.getElementById('closeModalButton').click();
        }
        setIsSaving(isSaving)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaving]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { setError(error) }, [error])


    useEffect(() => {
        if (applicationDetail !== undefined && applicationDetail !== null) {
            setAlias(applicationDetail.alias);
            setName(applicationDetail.name);
            setIcon(applicationDetail.icon);
            setPosition(applicationDetail.position);
            setHomepageHide(applicationDetail.homepageHide);
            setMenuHide(applicationDetail.menuHide);
        }
    }, [applicationDetail])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (insertNew) {
            var newApplicationObj = {
                alias: alias,
                name: name,
                icon: icon,
                homepageHide: homepageHide,
                menuHide: menuHide,
                position: position
            }
            setNewApplication(newApplicationObj);
        }
        else {
            var applicationObj = {
                id: applicationDetail.id,
                alias: alias,
                name: name,
                icon: icon,
                homepageHide: homepageHide,
                menuHide: menuHide,
                position: position
            }
            setApplication(applicationObj);
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="modal fade" id="applicationDetailModal" tabIndex="-1" aria-labelledby="applicationDetailModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="applicationDetailModalLabel">Dettaglio</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='closeModalButton'></button>
                        </div>
                        {
                            applicationDetail === undefined
                                ?
                                <></>
                                :
                                <div className="modal-body">
                                    <br />
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" value={alias} disabled={!insertNew} onChange={(e) => setAlias(e.target.value)} required />
                                        <label >Alias</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                                        <label >Nome</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" value={icon} onChange={(e) => setIcon(e.target.value)} required />
                                        <label >Icona</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" value={position} onChange={(e) => setPosition(e.target.value)} required />
                                        <label >Posizione</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={homepageHide} onChange={(e) => setHomepageHide(e.target.checked)} />
                                        <label className="form-check-label" >
                                            Nascondi in homepage
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={menuHide} onChange={(e) => setMenuHide(e.target.checked)} />
                                        <label className="form-check-label" >
                                            Nascondi nel menu
                                        </label>
                                    </div>
                                </div>
                        }

                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Salva</button>
                            <button type="button" id="closeModalButton" className="btn btn-primary" data-bs-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Detail