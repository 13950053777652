/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { instance } from "../app"
import { useSelector } from 'react-redux';

const useFetchFlow = () => {
    const { user } = useSelector(store => store);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [flowPaginatedReq, setFlowPaginatedReq] = useState(undefined);
    const [flowPaginatedResp, setFlowPaginatedResp] = useState([]);
    const [isAccounting, setIsAccounting] = useState(false);
    const pageSize = process.env.REACT_APP_PAGINATION_PAGESIZE;

    const getPageNumber = (value) => {
        setCurrentPage(value);
    }

    //#region flow paginated
    const getFlowPaginatedReq = (object) => {
        setCurrentPage(1);
        setFlowPaginatedReq(object);
    }
    const getIsAccounting = (value) => {
        setIsAccounting(value);
    }

    const getFlowPaginated =
        async () => {
            try {
                if (flowPaginatedReq === undefined)
                    return null;
                if (user.email === null) {
                    return null;
                }
                setIsLoading(true);
                var accountingUrl = isAccounting ? "accounting/" : "";
                var response = await instance.post(`/flow/${accountingUrl}flows-paginated?pageSize=${pageSize}&pageNumber=${currentPage}&sort=-Numero`, flowPaginatedReq);
                setFlowPaginatedResp(response.data);
            } catch (error) {
                console.log("error: " + error);
            }
            setIsLoading(false);
        }

    useEffect(() => {
        getFlowPaginated();
    }, [flowPaginatedReq, currentPage]);
    //#endregion

    return { flowPaginatedResp, getFlowPaginatedReq, isLoading, getPageNumber, getIsAccounting }
}

export default useFetchFlow