import { useEffect, useState } from 'react'
import { instance } from './app';
import { useSelector } from 'react-redux';

const useAzureFunction = () => {
    const { user } = useSelector(store => store);
    const [syncInvoiceReq, setSyncInvoiceReq] = useState({ supplyType: null, email: null });
    const [uploadFlowReq, setUploadFlowReq] = useState({ flowName: null, email: null });
    // const [isSendingSyncInvoice, setIsSendingSyncInvoice] = useState(false);

    //#region put syncinvoice message
    const syncInvoices = async () => {
        try {
            if (!user.loggedIn)
                return;
            if (syncInvoiceReq.email === null && syncInvoiceReq.supplyType === null)
                return;

            var response;
            if (syncInvoiceReq.email === null)
                response = await instance.put(`/azurefunction/sync-invoices?supplyType=${syncInvoiceReq.supplyType}`, null);
            else
                response = await instance.put(`/azurefunction/sync-invoices?supplyType=${syncInvoiceReq.supplyType}&email=${syncInvoiceReq.email}`, null);
            if (response.status !== 200)
                console.error("Response status was not 200, here the response: " + response.message);
        } catch (error) {
            console.error(error);
            console.error(error.response.data.error.message);
        }
    }
    //#endregion

    //#region put upload flow message
    const uploadFlow = async () => {
        try {
            if (!user.loggedIn)
                return;
            if (uploadFlowReq.email === null && uploadFlowReq.flowName === null)
                return;

            var response;
            if (uploadFlowReq.email === null)
                response = await instance.put(`/azurefunction/upload-flow?flowName=${uploadFlowReq.flowName}`, null);
            else
                response = await instance.put(`/azurefunction/upload-flow?flowName=${uploadFlowReq.flowName}&email=${uploadFlowReq.email}`, null);
            if (response.status !== 200)
                console.error("Response status was not 200, here the response: " + response.message);
        } catch (error) {
            console.error(error);
            console.error(error.response.data.error.message);
        }
    }
    //#endregion

    useEffect(() => {
        syncInvoices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncInvoiceReq])

    useEffect(() => {
        uploadFlow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadFlowReq])

    return {
        setSyncInvoiceReq,
        setUploadFlowReq
    }
}

export default useAzureFunction