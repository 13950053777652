import React from 'react'
import LoadingImg from '../../img/loading_ab.gif'

const Loading = () => {
    return (
        <div className='container container-centered' style={{ "textAlign": "center" }}>
            <img src={LoadingImg} alt='img' />
        </div>
    )
}

export default Loading