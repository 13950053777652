import React, { useEffect, useState } from 'react'
import useFetchSetup from '../../CustomHook/FetchData/useFetchSetup'
import Loading from '../Shared/Loading'
import useSetup from '../../CustomHook/useSetup'

const InvoiceTypeEditModal = ({ id }) => {
    const { isProtocolLoading, setProtocolId, protocolResp } = useFetchSetup();
    const { setUpdateProtocolReq, isSavingProtocol, errorUpdateProtocol } = useSetup();

    const [filter, setFilter] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [soselProtocol, setSoselProtocol] = useState(undefined);

    useEffect(() => {
        setProtocolId(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (filter === undefined && code === undefined && soselProtocol === undefined) {
            document.getElementById('closeModalButton').click();
            return;
        }
        var request = {
            id,
            filter: filter === undefined ? null : filter,
            code: code === undefined ? null : code,
            soselProtocol: soselProtocol === undefined ? null : soselProtocol
        }
        setUpdateProtocolReq(request);
    }

    useEffect(() => {
        if (!isSavingProtocol)
            if (errorUpdateProtocol !== undefined)
                alert("ERROR: " + errorUpdateProtocol)
            else {
                document.getElementById('closeModalButton').click();
                window.location.reload(false);
            }
    }, [isSavingProtocol, errorUpdateProtocol])

    return (
        <form onSubmit={handleSubmit}>
            <div className="modal fade" id="invoiceTypeEdit" tabIndex="-1" aria-labelledby="invoiceTypeEditLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="invoiceTypeEditLabel">Modifica protocollo</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='closeModalButton'></button>
                        </div>
                        <div className="modal-body">
                            {
                                isProtocolLoading ?
                                    <Loading />
                                    :
                                    protocolResp !== undefined
                                        ?
                                        <div>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" defaultValue={protocolResp.id} disabled />
                                                <label >Id</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" defaultValue={protocolResp.value} onChange={(e) => setFilter(e.target.value)} />
                                                <label >Filtro</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" defaultValue={protocolResp.code} onChange={(e) => setCode(e.target.value)} />
                                                <label >Codice</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" defaultValue={protocolResp.soselProtocol} onChange={(e) => setSoselProtocol(e.target.value)} />
                                                <label >Protocollo SOSEL</label>
                                            </div>
                                        </div>
                                        :
                                        <></>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                            <button type="submit" className="btn btn-primary" >Salva</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default InvoiceTypeEditModal