import React, { useEffect } from 'react'
import useFetchAdmin from '../../../CustomHook/FetchData/useFetchAdmin'
import { useSelector } from 'react-redux';
import Loading from '../../Shared/Loading'

const Table = ({ setApplicationId, isSaving }) => {
    const { user } = useSelector(store => store);
    const { getApplications, applications, isLoading } = useFetchAdmin();

    useEffect(() => {
        if (isSaving === false)
            getApplications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaving])

    useEffect(() => {
        if (user.loggedIn)
            getApplications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.checking])

    return (
        isLoading ?
            <Loading />

            :

            <div className="table-responsive-lg">
                <table className="table">
                    <thead>
                        <tr >
                            <th scope="col">Posizione</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Alias</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    {
                        applications === undefined ?
                            <tbody className='text-center'>
                                <tr>
                                    <td colSpan="12">Nessun risultato</td>
                                </tr>
                            </tbody>
                            :
                            <tbody>
                                {
                                    applications.map((el, index) => {
                                        return (
                                            <tr key={index} className="form-control-sm" valign="middle">
                                                <td>{el.position}</td>
                                                <td>{el.name}</td>
                                                <td>{el.alias}</td>
                                                <td>
                                                    {/* <button type='button' className='btn btn-primary' id={el.id}>Dettagli</button> */}
                                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#applicationDetailModal" onClick={() => { setApplicationId(el.id) }}>Dettagli</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                    }
                </table>
            </div>
    )
}

export default Table