import React, { useEffect } from 'react'
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import useExportExcel from '../../../CustomHook/useExportExcel';
import { ShowInfoNotification, RemoveNotification } from '../Notification';

const ToastPrinting = ({ invoiceToPrint, errorCreation }) => {
    const { isLoading, getExportRequest, exportResponse } = useExportExcel();

    useEffect(() => {
        if (Object.keys(invoiceToPrint).length === 0) {
            new bootstrap.Toast(document.querySelector('#basicToast')).hide()
            return;
        }
        const className = document.getElementById("basicToast").className;
        if (invoiceToPrint.length > 0 && className.includes("hide")) {
            new bootstrap.Toast(document.querySelector('#basicToast')).show()
        }
    }, [invoiceToPrint]);

    const notificationId = "ExportingNotification";

    const Download = (e) => {
        e.preventDefault();
        ShowInfoNotification(notificationId, "Esportazione...");
        getExportRequest(invoiceToPrint.map((item) => { return item.invoiceNumber }), errorCreation);
    }
    useEffect(() => {
        if (!isLoading) {
            RemoveNotification(notificationId);
            if (exportResponse === null)
                alert("Nessun dato da esportare");
            const link = document.createElement('a');
            const fileName = 'Errors.xlsx';
            link.setAttribute('download', fileName);
            link.href = URL.createObjectURL(new Blob([exportResponse], { type: "application/octet-stream" }));
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }, [isLoading, exportResponse])
    return (
        <div className="position-fixed bottom-0 end-0 p-3">
            <style>{`z-index: 11`}</style>
            <div id="basicToast" className="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
                <div className="toast-header">
                    <strong className="me-auto">Opzioni</strong>
                </div>
                <div className="toast-body">
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createFlowModal">Creazione flusso</button>
                </div>
                <div className="toast-body">
                    <button type="button" className="btn btn-primary" onClick={Download} >Esporta selezionati</button>
                </div>
                {
                    errorCreation === true ?
                        <div className="toast-body" id='errorExport'>
                            <button type="button" className="btn btn-primary" onClick={Download} >Estrai errori</button>
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default ToastPrinting