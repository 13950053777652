import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    error: {
        status: false,
        message: ""
    },
    data: []
}

const apiSlice = createSlice({
    name: "applications",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.data = []
        },
        stopLoading: (state) => {
            state.loading = false
        },
        saveData: (state, action) => {
            state.data = action.payload;
        },
        clearData: (state) => {
            state.data = [];
        },
        catchError: (state, action) => {
            state.error.status = true;
            state.error.message = action.payload;
            state.data = []
        },
        cleanError: (state) => {
            state.error.status = false;
            state.error.message = "";
        }
    }
})

export const { startLoading, stopLoading, saveData, catchError, cleanError, clearData } = apiSlice.actions;
const { reducer } = apiSlice;

export default reducer;