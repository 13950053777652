import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { logout } from '../../redux/Functions/userReduxFunctions';

import "../../assets/dist/js/tooltip"

//FONT
import "../../assets/dist/css/font.css"
//Bootstrap core CSS
import "../../assets/dist/css/bootstrap.min.css"
//Custom styles for this template
import "../../css/default.css"
import logo from '../../img/logo.png'

import { useSelector, useDispatch } from 'react-redux';
import { clearData } from '../../redux/reducers/applicationsReducer';
import { resetPassword } from '../../redux/Functions/userReduxFunctions';

//font awesome
library.add(fas);

const Navbar = () => {
    const { applications, user } = useSelector(store => store);
    const dispatch = useDispatch();

    const handleNavbar = () => {
        const el = document.querySelector('#navbarsHeader');
        if (el.classList.contains("show")) {
            el.classList.remove("show");
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(clearData());
        dispatch(logout());
    }

    const handleResetPassword = () => {
        dispatch(clearData());
        dispatch(resetPassword(user.email, true));
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                width: '100%'
            }}
        >
            <nav className="navbar navbar-expand-lg navbar-header" aria-label="navbar">
                <div className="container-fluid">
                    <img src={logo} alt="abenergie" height={40} />
                    {
                        applications.loading ?
                            <></>
                            :
                            <button className="navbar-toggler custom-toggler order-1 order-lg-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsHeader" aria-controls="navbarsHeader" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                    }
                    <div className="navbar-collapse order-lg-2 collapse" id="navbarsHeader">
                        <button type="button" id="hideNavbarHeader" className="btn-close" aria-label="Close" onClick={() => { handleNavbar() }}></button>
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {
                                applications.loading ?
                                    <></>
                                    :
                                    applications.data.map((el, index) => {
                                        return (
                                            <li className="nav-item" key={index} data-bs-toggle="tooltip" data-bs-placement="bottom" title={el.applicationName}>
                                                <Link className="nav-link" key={el.alias} to={"/" + el.alias.toLowerCase()} >
                                                    <FontAwesomeIcon icon={el.icon} title={el.applicationName} />
                                                </Link>
                                            </li>
                                        )
                                    })
                            }
                            {
                                applications.loading || !user.loggedIn ?
                                    <></>
                                    :
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon="fa-light fa-user" />
                                        </Link>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><Link className="dropdown-item" to="/home" onClick={() => { handleResetPassword() }}>Reset password</Link></li>
                                            <li>
                                                <Link className="dropdown-item" to="/" onClick={handleLogout}>
                                                    Logout
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                            }
                        </ul>
                    </div>
                    {
                        applications.loading || !user.loggedIn ?
                            <></>
                            :
                            <Link className="nav-item-icon d-flex align-self-middle order-3 order-lg-3" to={"/"} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="23.726" height="20.768" viewBox="0 0 23.726 20.768">
                                    <g id="Raggruppa_787" data-name="Raggruppa 787" transform="translate(0 0)">
                                        <g id="Raggruppa_760" data-name="Raggruppa 760" transform="translate(5.31 1.92)" opacity="0.25">
                                            <g id="Raggruppa_727" data-name="Raggruppa 727" transform="translate(0 0)">
                                                <g id="Raggruppa_726" data-name="Raggruppa 726">
                                                    <g id="Raggruppa_725" data-name="Raggruppa 725">
                                                        <path id="Tracciato_160" data-name="Tracciato 160" d="M1721.408,358.483l7.3,7.8v10.295h-6.283v-3.412a2.582,2.582,0,0,0-2.46-2.579l-.121,0a2.582,2.582,0,0,0-2.579,2.46l0,.121v3.412h-3.878v-9.87Zm-1.823,5.843a.866.866,0,1,0,.853.866.86.86,0,0,0-.853-.866" transform="translate(-1713.38 -358.483)" fill="#0065A7" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Raggruppa_761" data-name="Raggruppa 761" transform="translate(2.144 9.422)">
                                            <path id="Tracciato_161" data-name="Tracciato 161" d="M1726.546,383.193H1718.8v-3.711a1.644,1.644,0,1,0-3.288,0v3.711h-7.776V371.847h1.721v9.625h4.334v-1.99a3.365,3.365,0,1,1,6.73,0v1.99h4.3v-9.625h1.721Z" transform="translate(-1707.739 -371.847)" fill="#0065A7" />
                                        </g>
                                        <g id="Raggruppa_762" data-name="Raggruppa 762" transform="translate(0 0.08)">
                                            <path id="Tracciato_162" data-name="Tracciato 162" d="M1725.858,367.329l-10.138-10.312a.307.307,0,0,0-.436,0l-10.136,10.311-1.228-1.206,10.138-10.312a2.031,2.031,0,0,1,2.866-.024l10.162,10.336Z" transform="translate(-1703.92 -355.206)" fill="#0065A7" />
                                        </g>
                                        <g id="Raggruppa_764" data-name="Raggruppa 764" transform="translate(8.941 6.042)">
                                            <path id="Tracciato_164" data-name="Tracciato 164" d="M1722.422,371a2.587,2.587,0,1,1,2.574-2.587A2.584,2.584,0,0,1,1722.422,371Zm0-3.453a.866.866,0,1,0,.853.866A.861.861,0,0,0,1722.422,367.547Z" transform="translate(-1719.847 -365.826)" fill="#0065A7" />
                                        </g>
                                        <g id="Raggruppa_765" data-name="Raggruppa 765" transform="translate(12.39 0)">
                                            <path id="Tracciato_165" data-name="Tracciato 165" d="M1735.9,362.375l-.841-.181a5.841,5.841,0,0,1-2.408-1.279,4.7,4.7,0,0,1-3.254-.232,15.1,15.1,0,0,1-2.8-2.484l-.6-.62,1.24-1.194.6.62a13.608,13.608,0,0,0,2.417,2.183,2.2,2.2,0,0,0,1.195.163,2.689,2.689,0,0,1-.207-.757,2.751,2.751,0,0,1,.766-2.12,4.133,4.133,0,0,1,2.893-1.41,2.383,2.383,0,0,1,2.4,1.813,2.714,2.714,0,0,1-1.241,2.545,7.243,7.243,0,0,1-1.379.806,3.01,3.01,0,0,0,.741.285l.841.181Zm-.986-5.591h-.006a2.5,2.5,0,0,0-1.617.83,1.108,1.108,0,0,0-.344.784,1.321,1.321,0,0,0,.244.573,6.427,6.427,0,0,0,1.813-.909c.4-.311.642-.667.6-.888C1735.567,356.973,1735.231,356.784,1734.91,356.784Z" transform="translate(-1725.992 -355.063)" fill="#0065A7" />
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                    }

                </div>
            </nav >
        </div>
    )
}

export default Navbar;