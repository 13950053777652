import React, { useState, useEffect } from 'react'
import Loading from '../../../Shared/Loading';

const UserDetail = ({ userDetailResp, isLoadingUserDetail }) => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [insertDate, setInsertDate] = useState("");

    useEffect(() => {
        if (!isLoadingUserDetail) {
            setName(userDetailResp.name);
            setSurname(userDetailResp.surname);
            setEmail(userDetailResp.email);
            setInsertDate(userDetailResp.insertDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingUserDetail])
    return (
        <div className='container'>
            {
                isLoadingUserDetail ?
                    <Loading />
                    :
                    <form>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-10'>
                                    <h3>Dettagli</h3>
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={userDetailResp.id} disabled />
                                <label >Id</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={name} disabled onChange={(e) => setName(e.target.value)} required />
                                <label >Nome</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={surname} disabled onChange={(e) => setSurname(e.target.value)} required />
                                <label >Cognome</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={email} disabled onChange={(e) => setEmail(e.target.value)} required />
                                <label >Email</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={insertDate} disabled onChange={(e) => setInsertDate(e.target.value)} required />
                                <label >Data inserimento</label>
                            </div>
                            <br />
                        </div>
                    </form>
            }
        </div>
    )
}

export default UserDetail