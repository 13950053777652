import { useState, useEffect } from "react";
import { instance } from './app';

const useFile = () => {
    //#region Red Zone Variables
    const [isLoadingRaiFee, setIsLoadingRaiFee] = useState(false);
    const [bodyRaiFee, setBodyRaiFee] = useState();
    const [errorMessageRaiFee, setErrorMessageRaiFee] = useState(undefined);
    const [isErrorRaiFee, setIsErrorRaiFee] = useState();
    //#endregion
    //#region Bonus Ee Variables
    const [isLoadingBonusEe, setIsLoadingBonusEe] = useState(false);
    const [bodyBonusEe, setBodyBonusEe] = useState();
    const [errorMessageBonusEe, setErrorMessageBonusEe] = useState(undefined);
    const [isErrorBonusEe, setIsErrorBonusEe] = useState();
    //#endregion
    //#region Bonus Ee Variables
    const [isLoadingAbNow, setIsLoadingAbNow] = useState(false);
    const [bodyAbNow, setBodyAbNow] = useState();
    const [errorMessageAbNow, setErrorMessageAbNow] = useState(undefined);
    const [isErrorAbNow, setIsErrorAbNow] = useState();
    //#endregion


    //#region upload File rai fee
    const uploadFileRaiFee =
        async () => {
            try {
                if (bodyRaiFee === undefined)
                    return;
                setIsLoadingRaiFee(true);
                await instance.post(`/file/rai-fee`, bodyRaiFee).then(response => {
                    if (response.status !== 200) {
                        var err = "The status returned is " + response.status + "\n" +
                            "Here below the response:\n " + response
                        setErrorMessageRaiFee(err);
                        setIsErrorRaiFee(true);
                    }
                });
                setIsLoadingRaiFee(false);
                setIsErrorRaiFee(false);
            } catch (error) {
                setErrorMessageRaiFee(error);
                setIsErrorRaiFee(true);
            }
        }
    //#endregion

    //#region upload File electrical bonus
    const uploadFileBonusEe =
        async () => {
            try {
                if (bodyBonusEe === undefined)
                    return;
                setIsLoadingBonusEe(true);
                await instance.post(`/file/electrical-bonus`, bodyBonusEe).then(response => {
                    if (response.status !== 200) {
                        var err = "The status returned is " + response.status + "\n" +
                            "Here below the response:\n " + response
                        setErrorMessageBonusEe(err);
                        setIsErrorBonusEe(true);
                    }
                });
                setIsLoadingBonusEe(false);
                setIsErrorBonusEe(false);
            } catch (error) {
                setErrorMessageBonusEe(error);
                setIsErrorBonusEe(true);
            }
        }
    //#endregion

    //#region upload File ab now
    const uploadFileAbNow =
        async () => {
            try {
                if (bodyAbNow === undefined)
                    return;
                setIsLoadingAbNow(true);
                await instance.post(`/file/abnow`, bodyAbNow).then(response => {
                    if (response.status !== 200) {
                        var err = "The status returned is " + response.status + "\n" +
                            "Here below the response:\n " + response
                        setErrorMessageAbNow(err);
                        setIsErrorAbNow(true);
                    }
                });
                setIsLoadingAbNow(false);
                setIsErrorAbNow(false);
            } catch (error) {
                setErrorMessageAbNow(error);
                setIsErrorAbNow(true);
            }
        }
    //#endregion

    useEffect(() => {
        uploadFileRaiFee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyRaiFee])

    useEffect(() => {
        uploadFileBonusEe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyBonusEe])

    useEffect(() => {
        uploadFileAbNow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyAbNow])

    return {
        setBodyRaiFee,
        isLoadingRaiFee,
        isErrorRaiFee,
        errorMessageRaiFee,
        setBodyBonusEe,
        isLoadingBonusEe,
        isErrorBonusEe,
        errorMessageBonusEe,
        setBodyAbNow,
        isLoadingAbNow,
        isErrorAbNow,
        errorMessageAbNow
    }
}

export default useFile