import React, { useEffect } from 'react'
import useFetchFunction from '../../../../CustomHook/FetchData/useFetchFunction';
import { useSelector } from 'react-redux';
import Loading from '../../../Shared/Loading';
import useFunction from '../../../../CustomHook/useFunction';

const RolesDetail = ({ functionId, setFunctionRolesUnassigned, reloadFunctionRoles }) => {
    const { user } = useSelector(store => store);
    const { setFunctionId, getFunctionRoles, functionRoles, functionRolesUnassigned, isLoadingFunctionRoles, isLoadingFunctionRolesUnassigned } = useFetchFunction();
    const { setRemoveRoleReq, isRemoveRoleToFunction } = useFunction();

    useEffect(() => {
        if (user.loggedIn)
            setFunctionId(functionId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functionId, user.checking])

    useEffect(() => {
        if (reloadFunctionRoles)
            getFunctionRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadFunctionRoles])

    useEffect(() => {
        if (!isRemoveRoleToFunction)
            getFunctionRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRemoveRoleToFunction])

    useEffect(() => {
        setFunctionRolesUnassigned(functionRolesUnassigned)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingFunctionRolesUnassigned])

    const removeRole = (roleId) => {
        setRemoveRoleReq({ functionId: functionId, roleId: roleId });
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-10'>
                    <h3>Ruoli associati a questa funzione</h3>
                </div>
                <div className='col-md-2'>
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#assignRoleModal">Associa nuovo ruolo</button>
                </div>
            </div>
            {
                isLoadingFunctionRoles ?
                    <Loading />
                    :
                    <div className="table-responsive-lg">
                        <table className="table">
                            <thead>
                                <tr >
                                    <th scope="col">Nome</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            {
                                functionRoles === undefined ?
                                    <tbody className='text-center'>
                                        <tr>
                                            <td colSpan="12">Nessun risultato</td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        {
                                            functionRoles.map((el, index) => {
                                                return (
                                                    <tr key={index} className="form-control-sm" valign="middle">
                                                        <td>{el.name}</td>
                                                        <td align='right'>
                                                            <button type='button' className='btn btn-danger' onClick={() => { removeRole(el.id) }}>Rimuovi</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                            }
                        </table>
                    </div>
            }
        </div>
    )
}

export default RolesDetail