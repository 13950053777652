import React, { useEffect } from 'react';
import useInsertFlow from '../../CustomHook/useInsertFlow';
import { useSelector } from 'react-redux';

const CreateFlowModal = ({ invoicesToPrint, supplyType }) => {
    const { user } = useSelector(store => store);
    const { getInsertFlowRequest, getInsertFlowAccountingRequest, isLoading, flowError } = useInsertFlow();
    const isTest = process.env.REACT_APP_TEST.toLowerCase() === "true";

    function AlertError() {
        return <div className="alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert" >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="exclamation-triangle" className="svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" viewBox="0 0 576 512"><g className="fa-group"><path className="fa-secondary" fill="currentColor" d="M569.52 440L329.58 24c-18.44-32-64.69-32-83.16 0L6.48 440c-18.42 31.94 4.64 72 41.57 72h479.89c36.87 0 60.06-40 41.58-72zM288 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 297.52 352h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 265.68 192h44.64a16 16 0 0 1 15.92 17.59z" opacity="0.4" /><path className="fa-primary" fill="currentColor" d="M310.32 192h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 278.48 352h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 310.32 192zM288 384a32 32 0 1 0 32 32 32 32 0 0 0-32-32z" /></g></svg>
            <div className="text-alert">
                Errore durante la generazione del flusso. <br />
                <u><b>{flowError}</b></u>
            </div>
        </div>
    }
    function AlertSuccess() {
        return <div className="alert alert-success d-flex align-items-center alert-dismissible fade show" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="exclamation-triangle" className="svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" viewBox="0 0 576 512"><g className="fa-group"><path className="fa-secondary" fill="currentColor" d="M569.52 440L329.58 24c-18.44-32-64.69-32-83.16 0L6.48 440c-18.42 31.94 4.64 72 41.57 72h479.89c36.87 0 60.06-40 41.58-72zM288 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 297.52 352h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 265.68 192h44.64a16 16 0 0 1 15.92 17.59z" opacity="0.4" /><path className="fa-primary" fill="currentColor" d="M310.32 192h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 278.48 352h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 310.32 192zM288 384a32 32 0 1 0 32 32 32 32 0 0 0-32-32z" /></g></svg>
            <div className="text-alert">
                <b>Flusso creato correttamente</b>
            </div>
        </div>
    }
    function ManageAlerts() {
        switch (flowError) {
            case "NoError":
                return <AlertSuccess />
            case "":
                return <></>
            default:
                return <AlertError />
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var noImport = document.getElementById("flagCheckNoImport").checked
        var autobasket = document.getElementById("flagCheckAutobasket").checked
        var sendCustomer = document.getElementById("flagCheckSendToCustomer").checked
        var electronicInvoicing = document.getElementById("flagCheckElectronicInvoicing").checked
        var sendToCustomerDate = null;
        var username = user.email;
        if (sendCustomer)
            sendToCustomerDate = document.getElementById("textSendToCustomerDate").value === "" ? alert("Inserire una data") : document.getElementById("textSendToCustomerDate").value;
        //if noImport checkbox if flagged, electronic invoicing and sent customer are set to false
        //otherwise SOSEL send invoice to SDI
        if (noImport === true) {
            electronicInvoicing = false;
            sendCustomer = false;
        }
        if (supplyType !== "AMM") {
            var excludeSciScf = document.getElementById("flagCheckExludeSciScf").checked
            getInsertFlowRequest({
                Company: process.env.REACT_APP_API_COMPANY,
                SupplyType: supplyType,
                Autobasket: isTest ? true : autobasket,
                NoImport: noImport,
                SendToCustomer: sendCustomer,
                SendDate: sendToCustomerDate,
                Username: username,
                ElectronicInvoicing: electronicInvoicing,
                ExcludeSciScf: excludeSciScf,
                InvoiceToPrint: invoicesToPrint
            });
        }
        else
            getInsertFlowAccountingRequest({
                Company: document.getElementById("accountingCompanies").value,
                Autobasket: isTest ? true : autobasket,
                NoImport: noImport,
                SendToCustomer: sendCustomer,
                SendDate: sendToCustomerDate,
                Username: username,
                ElectronicInvoicing: electronicInvoicing,
                InvoiceToPrint: invoicesToPrint
            });
    };

    useEffect(() => {
        if (!isLoading)
            if (flowError === "NoError") {
                setTimeout(() => {
                    document.getElementById('closeModalButton').click();
                    document.getElementById('btnInvoiceListSearch').click();
                }, 2000)
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])
    return (
        <form onSubmit={handleSubmit}>
            <div className="modal fade" id="createFlowModal" tabIndex="-1" aria-labelledby="createFlowModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createFlowModalLabel">Creazione flusso
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='closeModalButton'></button>
                        </div>
                        <div className="modal-body">
                            <div>

                                {
                                    invoicesToPrint.length > 1
                                        ?
                                        <label className="form-check-label text-secondary">Fatture selezionate: {invoicesToPrint.length} </label>
                                        :
                                        <label className="form-check-label text-secondary">Fattura selezionata: {invoicesToPrint.length} </label>
                                }

                            </div>
                            <br />
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flagCheckNoImport" />
                                <label className="form-check-label text-secondary">
                                    Non Importare
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flagCheckAutobasket" />
                                <label className="form-check-label text-secondary">
                                    Autobasket
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flagCheckSendToCustomer" />
                                <label className="form-check-label text-secondary">
                                    Invio al cliente
                                </label>&nbsp;&nbsp;
                                <input className="form-control-input" type="date" aria-label="default input example"
                                    id="textSendToCustomerDate" style={{ width: "150px" }} />
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flagCheckElectronicInvoicing" />
                                <label className="form-check-label text-secondary">
                                    Fatturazione elettronica
                                </label>
                            </div>
                            {
                                supplyType !== "AMM" ?
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flagCheckExludeSciScf" />
                                        <label className="form-check-label text-secondary">
                                            Escludi SCI SCF
                                        </label>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                        {
                            <ManageAlerts />
                        }
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Crea flusso</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annulla e chiudi</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CreateFlowModal