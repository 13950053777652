/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { DOTS } from '../../CustomHook/usePagination';
import TotalPages from './TotalPages';

const Pagination = ({ paginationRange, currentPage, goToPreviousPage, changePage, data, goToNextPage }) => {
    return (
        <div className='row'>
            <div className='col-md-10'>
                {
                    (currentPage === 0 || paginationRange.length < 2)
                        ?
                        <></>
                        :
                        <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <a className="page-link" aria-label="Previous" href='#' onClick={goToPreviousPage}>
                                    <span aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 24 24">
                                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                    </span>
                                </a>
                            </li>
                            {
                                paginationRange.map((pageNumber, index) => {
                                    if (pageNumber === DOTS) {
                                        return <li className="page-item disabled" key={index}><a className="page-link">&#8230;</a></li>
                                    }

                                    return <li className={`page-item${currentPage === pageNumber ? ' current' : ''}`} key={index}><a className="page-link" href="#" onClick={changePage}>{pageNumber}</a></li>
                                })
                            }
                            <li className={`page-item ${currentPage === data.pages ? 'disabled' : ''}`}>
                                <a className="page-link" href="#" aria-label="Next" onClick={goToNextPage}>
                                    <span aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 24 24">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </span>
                                </a>
                            </li>
                        </ul>
                }
            </div>
            {
                (data.count === undefined)
                    ? <></>
                    :
                    <TotalPages total={data.count} />
            }
        </div>
    )
}

export default Pagination