import { instance } from "../app"
import { useEffect, useState } from 'react'

const useFetchNav = () => {
    const [isLoadingNav, setIsLoadingNav] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState(undefined);
    const [errorMessageNav, setErrorMessageNav] = useState(undefined);
    const [navResponse, setNavResponse] = useState(undefined);
    const [isLoadingNavSession, setIsLoadingNavSession] = useState(false);
    const [navSessionResponse, setNavSessionResponse] = useState(undefined);

    const getNavCall =
        async () => {
            try {
                if (invoiceNumber === undefined)
                    return;
                setIsLoadingNav(true);
                await instance.get(`/nav/layout-call/${invoiceNumber}`).then(response => {
                    if (response.status !== 200) {
                        var err = "The status returned is " + response.status + "\n" +
                            "Here below the response:\n " + response
                        setErrorMessageNav(err);
                        setIsLoadingNav(false);
                        return;
                    }
                    setNavResponse(response.data);
                })
                    .catch((error) => {
                        setNavResponse(undefined);
                        setErrorMessageNav(error.response.data.error.message);
                        setIsLoadingNav(false);
                    });
                setIsLoadingNav(false);
            } catch (error) {
                setErrorMessageNav(error);
                setIsLoadingNav(false);
            }
        }

    const getNavSession = async () => {
        try {
            setIsLoadingNavSession(true);
            await instance.get(`/nav/users-logged`).then(response => {
                if (response.status !== 200) {
                    // var err = "The status returned is " + response.status + "\n" +
                    //     "Here below the response:\n " + response
                    // setErrorMessageNav(err);
                    setIsLoadingNavSession(false);
                    return;
                }
                setNavSessionResponse(response.data);
            })
                .catch((error) => {
                    setNavSessionResponse(undefined);
                    // setErrorMessageNav(error.response.data.error.message);
                    setIsLoadingNavSession(false);
                });
            setIsLoadingNavSession(false);
        } catch (error) {
            // setErrorMessageNav(error);
            setIsLoadingNavSession(false);
        }
    }

    useEffect(() => {
        getNavCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceNumber])

    return {
        setInvoiceNumber,
        getNavSession,
        isLoadingNav,
        isLoadingNavSession,
        navResponse,
        errorMessageNav,
        navSessionResponse
    }
}

export default useFetchNav